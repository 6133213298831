import React from 'react'
import { CustomRow, GroupContentTr, GroupName, GroupRow, Label, CheckBox } from './styles'
import { reduceBigTextLines } from '@/utils/functionUtils'
import { Table } from 'presentation/components'
import { Icon } from 'presentation/components/atoms'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

const Group = ({
  allGroup,
  checked,
  checkboxClick,
  displayName,
  filter,
  checkIfGroupIsChecked,
  group,
  groupName,
  disabled,
  isVisible,
  setIsVisible,
}) => {
  const generatedGroupRows = generateRows(group)

  function generateRows(list) {
    const filteredItems = list.filter((item) => item[displayName].toLowerCase().includes(filter?.toLowerCase() || ''))

    return filteredItems.map((item) => {
      return (
        <CustomRow key={item.id} disabled={disabled}>
          <Table.Td style={{ paddingLeft: 40 }}>
            <CheckBox controlled onClick={() => checkboxClick(item.id)} disabled={disabled}>
              <input type='checkbox' checked={checked.some((check) => check === item.id)} />
              <div />
            </CheckBox>
            <span className='employee'>{reduceBigTextLines(item[displayName], 25)}</span>
          </Table.Td>
        </CustomRow>
      )
    })
  }

  return (
    <>
      {generatedGroupRows.length > 0 && (
        <>
          <CustomRow disabled={disabled}>
            <Table.Td>
              <GroupRow>
                <CheckBox controlled onClick={() => allGroup(groupName)} disabled={disabled}>
                  <input type='checkbox' checked={!checkIfGroupIsChecked(groupName)} />
                  <div />
                </CheckBox>
                <GroupName checked={true} visible={isVisible}>
                  <Label onClick={() => setIsVisible(groupName)}>
                    {`${groupName} (${generatedGroupRows.length})`}
                    <Icon icon={faChevronUp} />
                  </Label>
                </GroupName>
              </GroupRow>
            </Table.Td>
          </CustomRow>
          <GroupContentTr visible={isVisible}>
            <Table.Td colSpan={2}>
              <Table>
                <tbody>{generatedGroupRows}</tbody>
              </Table>
            </Table.Td>
          </GroupContentTr>
        </>
      )}
    </>
  )
}

export default Group
