import { useContext, useEffect } from 'react'
import { MenuItem } from 'presentation/components/atoms'
import { DropdownButtonContext } from './dropdownButton'

interface DropdownButtonItemProps {
  children: any
  onClick?: () => void
}

export const DropdownButtonItem = ({ children, onClick }: DropdownButtonItemProps) => {
  const context = useContext<string>(DropdownButtonContext)

  useEffect(() => {
    if (context !== 'DropdownButton') throw new Error('DropdownButtonItem must be used inside of a DropdownButton')
  }, [])

  return <MenuItem onClick={onClick}>{children}</MenuItem>
}
