import { Box, Divider, Typography } from 'presentation/components/atoms'
import { formatMoney } from 'utils/functionUtils'
import { tooltipContent } from './utils/tooltip'
import { ReactComponent as CardIcon } from 'presentation/assets/icons/card.svg'
import { ReactComponent as RechargeIcon } from 'presentation/assets/icons/recharge.svg'

import { CustomButton, ItemsContainer, OrderDetailHeader, TotalValue } from './styles'
import { useState } from 'react'
import { Summary, ValuesSummary } from 'presentation/components/molecules'
import { ORDER_METHOD, ORDER_TYPES } from 'utils/constants'
import { ItemRemovalDialog } from './components/itemRemovalDialog/itemRemovalDialog'
import { useFeatures } from 'main/hooks'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { orderIdAtom } from '@/main/store'

type OrderType = 'recharge' | 'rechargeBroker' | 'rechargeImport' | 'firstCopy' | 'secondCopy'
interface ItemsProps {
  orders: any
  orderType: OrderType
  title: string
}

export interface ItemBasicInfo {
  orderId: string
  operatorCardName: string
  totalValue: number
  totalItens: number
}

export const Items = ({ orders, orderType, title }: ItemsProps) => {
  const navigate = useNavigate()
  const [orderToRemove, setOrderToRemove] = useState<ItemBasicInfo | null>(null)
  const { features } = useFeatures()
  const setOrderId = useSetAtom(orderIdAtom)

  const totalSumOfAllOrders = orders?.reduce((initValue: number, currentValue: any) => {
    return initValue + currentValue.totalValue
  }, 0)

  if (!(orders.length > 0)) return null

  const handleItemRemoval = ({ orderId, operatorCardName, totalValue, totalItens }: ItemBasicInfo) => {
    setOrderToRemove({
      orderId,
      operatorCardName,
      totalValue,
      totalItens,
    })
  }

  const handleItemModify = (order: any) => {
    const { orderType, orderId, method } = order
    let path = ''

    if (ORDER_TYPES.RECHARGE === orderType) {
      if (ORDER_METHOD.IMPORT === method) path = `/recargas/novo_pedido_importacao_valores`
      else path = '/recargas/novo_pedido_manual'
    } else if (ORDER_TYPES.BROKER === orderType) path = `/recargas/pedido_recarga_broker/dados`
    else if (ORDER_TYPES.FIRSTCOPY === orderType) path = `/cartoes/novo/primeira`
    else path = `/cartoes/novo/segunda_confirmacao`
    setOrderId(orderId)
    navigate(`${path}#orderId="${orderId}"`)
  }

  const OrderIcon = orderType === 'recharge' ? RechargeIcon : CardIcon

  return (
    <ItemsContainer>
      <Summary
        title={
          <Box sx={{ display: 'flex', justifyContent: 'spaceBetween' }}>
            <Typography variant='h4'>{title}</Typography>
            <TotalValue variant='h4'>{formatMoney(totalSumOfAllOrders)}</TotalValue>
          </Box>
        }
        subtitle={`${orders.length} ${orders.length > 1 ? 'itens' : 'item'}`}
        icon={<OrderIcon />}
      >
        {orders.map((order: any, index: number) => {
          return (
            <Box key={_.uniqueId()}>
              {index !== 0 && <Divider sx={{ margin: '16px 0' }} />}
              <OrderDetailHeader sx={{ marginBottom: '16px' }}>
                <Typography variant='label2'>
                  {index + 1}. {order.operatorCardName}
                </Typography>
                <CustomButton
                  onClick={() => {
                    handleItemRemoval({
                      orderId: order.orderId,
                      operatorCardName: order.operatorCardName,
                      totalValue: order.totalValue,
                      totalItens: order.totalItens,
                    })
                  }}
                >
                  Excluir
                </CustomButton>
                <CustomButton onClick={() => handleItemModify(order)}>Alterar</CustomButton>
              </OrderDetailHeader>
              <ValuesSummary
                rowSpacing='16px'
                values={[
                  { label: 'Colaboradores', value: order.totalItens, isMonetary: false },
                  {
                    label: orderType === 'recharge' ? 'Valor da recarga' : 'Valor do pedido',
                    value: order.totalRechargeValue,
                  },
                  { label: 'Taxa administrativa', value: order.administrateFee },
                  { label: 'Taxa de repasse', value: order.totalTransferFeeValue },
                  !features.ticketingBalance
                    ? null
                    : {
                        label: 'Conta corrente',
                        value: order.ticketingBalance,
                        color: 'green',
                        isNegative: true,
                        labelRightSideIcon: tooltipContent({ value: order.oldTicketingBalance }),
                        hideZero: order.oldTicketingBalance <= 0,
                      },
                  { label: 'Retenção ISS', value: order.issFee, color: 'green', isNegative: true },
                  { label: 'Valor total', value: order.totalValue, isBold: true, color: 'black' },
                ]}
              />
            </Box>
          )
        })}
      </Summary>
      <ItemRemovalDialog
        orderToRemove={orderToRemove}
        OrderIcon={OrderIcon}
        title={title}
        setOrderToRemove={setOrderToRemove}
      />
    </ItemsContainer>
  )
}
