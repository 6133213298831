import { useContext } from 'react'
import { useTheme } from '@mui/material'
import { useSetAtom } from 'jotai'
import _ from 'lodash'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Summary } from 'presentation/components/molecules'
import { Box, Card, Divider, Icon, NavLink, Typography } from 'presentation/components/atoms'
import { ORDER_STATUS, ORDER_STATUS_KEY, ORDER_TYPES } from 'main/utils/constants'
import { formatMoney } from 'main/utils/functions'
import { ReactComponent as CardIcon } from 'presentation/assets/icons/card.svg'
import { ReactComponent as RechargeIcon } from 'presentation/assets/icons/recharge.svg'
import { orderStatusToColors } from 'main/utils/functions'
import { orderIdAtom } from '@/main/store'
import { OrderTrackingContext } from '@/presentation/pages/orderTracking/orderTracking'
import { LinkToOrder, StatusBanner } from '../cartInfo/styles'

export const RelatedCartOrders = () => {
  const theme = useTheme()
  const setOrderId = useSetAtom(orderIdAtom)
  const { cart, isRecharge } = useContext(OrderTrackingContext)

  const renderCartOrders = () => {
    const orders = cart?.cartOrders
    return orders?.map((order) => {
      const isOrder = order.type > ORDER_TYPES.SECOND_COPY

      const orderLink = cart.purchaseId
        ? `/${isRecharge ? 'recargas' : 'cartoes'}/acompanhamento#orderId="${order.id}"&purchaseId="${
            cart.purchaseId
          }"&isDealerOrder=false`
        : `/${isRecharge ? 'recargas' : 'cartoes'}/acompanhamento#orderId="${order.id}"`

      const orderTitle =
        ORDER_TYPES.FIRST_COPY === order.type
          ? 'Cartão - 1ª via'
          : ORDER_TYPES.SECOND_COPY === order.type
          ? 'Cartão - 2ª via'
          : 'Recarga'
      let orderSubtitle = ''
      const colorScheme = orderStatusToColors({ status: order.status, theme: theme })
      if (order.type > 2)
        orderSubtitle = `${order.operatorName} ⚬ ${order.totalItems} ${
          order.totalItems > 1 ? 'colaboradores' : 'colaborador'
        }`
      else orderSubtitle = `${order.operatorName} ⚬ ${order.totalItems} ${order.totalItems > 1 ? 'cartões' : 'cartão'}`

      const handleLinkClick = ({ orderId }: { orderId: string }) => {
        setOrderId(orderId)
        document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
      }
      return (
        <Card sx={{ padding: '12px', marginTop: '21px' }} key={_.uniqueId()}>
          <StatusBanner color={colorScheme}>
            <Typography variant='h5' sx={{ fontWeight: 500 }}>
              {ORDER_STATUS[order.status as ORDER_STATUS_KEY]}
            </Typography>
          </StatusBanner>
          <Summary
            icon={isOrder ? <RechargeIcon /> : <CardIcon />}
            title={
              <Box sx={{ display: 'flex', gap: '32px', '& a': { color: 'inherit' } }}>
                <Typography variant='h6' sx={{ whiteSpace: 'nowrap' }}>
                  {orderTitle}
                </Typography>
                <NavLink
                  to={orderLink}
                  onClick={() => {
                    handleLinkClick({ orderId: order.id })
                  }}
                >
                  <Typography variant='h6'>#{order.code}</Typography>
                </NavLink>
                <Box sx={{ display: 'flex', alignItems: 'end', marginLeft: 'auto', gap: '4px' }}>
                  <Typography sx={{ fontSize: '.75rem' }}>R$</Typography>
                  <Typography variant='h6' sx={{ display: 'flex' }}>
                    {formatMoney(order.totalValue)}
                  </Typography>
                </Box>
              </Box>
            }
            subtitle={orderSubtitle}
          />
          <LinkToOrder
            to={orderLink}
            onClick={() => {
              handleLinkClick({ orderId: order.id })
            }}
          >
            Ver detalhes
            <Icon icon={faArrowUpRightFromSquare} />
          </LinkToOrder>
        </Card>
      )
    })
  }

  return (
    <>
      {cart?.cartOrders.length > 0 && (
        <>
          <Divider sx={{ margin: '9px 0 22px' }} />
          <Typography sx={{ fontWeight: 500 }}>Itens no carrinho</Typography>
        </>
      )}
      {renderCartOrders()}
    </>
  )
}
