import { createContext } from 'react'
import { IOrder } from 'main/interfaces'
import { Button, Divider, Grid, Paper } from 'presentation/components/atoms'
import { PageHeader } from 'presentation/components/molecules'
import { PaymentDetail } from './components/paymentDetail/paymentDetail'
import { StatusInformation } from './components/statusInformation/statusInformation'
import { OrderItems } from './components/orderItems/orderItems'
import { OrderTrackingSkeleton } from './components/orderTrackingSkeleton/orderTrackingSkeleton'
import { useMediaQuery } from '@mui/material'
import { Content } from 'presentation/components'

type ItemsContent = {
  orderItems: any
  isLoading: boolean
  filter: any
  goToPage: (page: number) => void
  setPageSize: (pageSize: number) => void
  updateFilter: (filterValues: object) => void
}
interface IOrderTrackingContext extends IOrder {
  isRecharge: boolean
  items: ItemsContent
}
export const OrderTrackingContext = createContext<IOrderTrackingContext>({} as IOrderTrackingContext)

interface OrderTrackingProps {
  order: any
  isRecharge: boolean
  isLoading: boolean
  handleReturn: () => void
  items: ItemsContent
}
export const OrderTracking = ({ order, isLoading, isRecharge, handleReturn, items }: OrderTrackingProps) => {
  const shouldBreak = useMediaQuery('@media (max-width:1150px)')

  /* const [idToCancel, setIdToCancel] = useState<string | null>(null) */
  /* const isCancelable = order?.orderStatus < ORDER_STATUS.WAITING_FOR_PROCESSING */

  return (
    <Content>
      <OrderTrackingContext.Provider value={{ ...order, isRecharge, items }}>
        <Paper sx={{ padding: '32px', overflow: 'auto' }}>
          <PageHeader pageTitle='Acompanhamento de Pedido' padding='0' noDivider />
          <Divider sx={{ margin: '32px 0' }} />
          {isLoading || !order ? (
            <OrderTrackingSkeleton />
          ) : (
            <Grid container spacing='32px' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={shouldBreak ? 12 : 8} lg={7}>
                <StatusInformation />
                <OrderItems />
              </Grid>
              <Grid item xs={12} sm={12} md={shouldBreak ? 12 : 4} lg={5}>
                <PaymentDetail />
              </Grid>
              {/* {isCancelable &&
                    <Grid item xs={12} sm='auto'>
                      <Button variant='outlined' color='error' onClick={() => setIdToCancel(order.orderId)} fullWidth>
                        Cancelar pedido
                      </Button>
                    </Grid>
                  } */}
              <Grid item xs={12} sm='auto' sx={{ marginLeft: 'auto' }}>
                <Button variant='outlined' onClick={handleReturn} fullWidth>
                  Voltar
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      </OrderTrackingContext.Provider>
      {/*   {!isLoading && order &&
            <OrderCancelationModal
              open={Boolean(idToCancel)}
              onCancel={() => setIdToCancel(null)}
              onConfirm={() => {
                if (idToCancel) {
                  cancelOrder.mutate({ orderId: idToCancel })
                  setIdToCancel(null)
                }
              }}
              orders={[
                {
                  code: order.orderCode,
                  id: order.orderId,
                  orderType: order.orderType,
                  totalValue: order.orderSummary.totalPaid,
                  totalItems: order.totalItems,
                },
              ]}
            />
          } */}
    </Content>
  )
}
