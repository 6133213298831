import { AccountPersistentStore } from '@/main/store/account'
import { useAtom } from 'jotai'
import { Navigate, useNavigate } from 'react-router-dom'

type VerifyAuthenticationProps = {
  isPublic?: boolean
  element: any
}

export const VerifyAuthentication = ({ element, isPublic = false }: VerifyAuthenticationProps) => {
  const navigate = useNavigate()
  const [account, setAccount] = useAtom(AccountPersistentStore)
  const isAuthenticated = Boolean(account.auth?.accessToken)

  const validateToken = () => {
    if (!account?.auth) return
    const { expiresIn } = account.auth
    const expireDate = new Date(expiresIn)
    const now = new Date()
    if (expireDate < now) {
      setAccount({})
      navigate(`/login`)
    }
  }

  validateToken()

  if (isAuthenticated && isPublic) return <Navigate to='/' />
  if ((isAuthenticated && !isPublic) || (!isAuthenticated && isPublic)) return element
  return <Navigate to='/login' />
}
