import { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ORDER_PAYMENT_TYPE } from 'utils/constants'
import { Box, Button, Divider, Paper, Typography } from 'presentation/components/atoms'
import { NoItemPaper } from './styles'
import { formatMoney } from 'utils/functionUtils'
import { CheckboxField, MaskedTextField, ValuesSummary } from 'presentation/components/molecules'
import { CartContext } from 'presentation/pages/cart/cart'
import { MethodPayment } from '../methodPayment/methodPayment'
import { useFeatures } from 'main/hooks/useFeatures'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from 'main/store'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'

export const Payment = () => {
  const { features } = useFeatures()
  const { push } = useNotifications()

  const [walletUsageError, setWalletUsageError] = useState('')
  const [shouldUseProjection, setShouldUseProjection] = useState(true)
  const {
    cart,
    confirmCart,
    isCartEmpty,
    totalBeforeBalanceUsage,
    balanceToBeUsed,
    setBalanceToBeUsed,
    method,
    setMethod,
  } = useContext(CartContext)
  const { accountBalance } = cart
  const account = useAtomValue(AccountPersistentStore)
  const total = totalBeforeBalanceUsage - balanceToBeUsed
  const hasRecharges = cart?.rechargeOrders?.length > 0

  const notAllowProjection = cart?.ticketingBalanceValue > 0

  if (isCartEmpty) {
    return (
      <NoItemPaper>
        <Typography variant='h2'>Pagamento</Typography>
        <Divider sx={{ margin: '32px 0' }} />
        <Typography variant='label2'>Aqui estarão as informações e valores do seu pedido.</Typography>
        <br />
        <Typography variant='label2'>Adicione ao menos um item aop carrinho para visualizar.</Typography>
      </NoItemPaper>
    )
  } else
    return (
      <Paper sx={(theme) => ({ padding: '16px', backgroundColor: theme.palette.grey[50] })}>
        <Typography variant='h2'>Pagamento</Typography>
        {features.methodPaymentCart ? (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', margin: '32px 0 12px' }}
          >
            <Typography style={{ marginBottom: 24 }} variant='subtitle2'>
              Método de pagamento
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <MethodPayment checked={method === 'SLIP'} name='Boleto' onClick={() => setMethod('SLIP')} />
              <MethodPayment checked={method === 'PIX'} name='Pix' onClick={() => setMethod('PIX')} />
            </Box>
          </Box>
        ) : null}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '32px 0 12px' }}>
          <Typography variant='subtitle2'>Saldo {account?.resale?.digitalAccountName}</Typography>
          <p>
            <small>R$</small> {formatMoney(cart.accountBalance)}
          </p>
        </Box>
        <Typography variant='label2' sx={{ fontWeight: 400, marginBottom: '16px', display: 'block' }}>
          Para usar o saldo no pagamento deste pedido, digite o valor abaixo.
        </Typography>
        <MaskedTextField
          label={`Valor de uso ${account?.resale?.digitalAccountName}`}
          placeholder='R$ 00,00'
          value={String(balanceToBeUsed)}
          helperText={walletUsageError}
          onChange={(value) => {
            let newValue = value || 0
            if (value > totalBeforeBalanceUsage) {
              newValue = totalBeforeBalanceUsage
              setWalletUsageError(
                `Você não pode exceder o valor total a ser pago de R$ ${formatMoney(totalBeforeBalanceUsage)}`,
              )
            } else if (value > accountBalance) {
              newValue = accountBalance
              setWalletUsageError(`Você não pode exceder o seu saldo atual de R$ ${formatMoney(accountBalance)}`)
            }
            setBalanceToBeUsed(newValue)
          }}
          mask={[
            { mask: '' },
            {
              mask: 'R$ num',
              lazy: true,
              blocks: {
                num: {
                  mask: Number,
                  scale: 2,
                  thousandsSeparator: '.',
                  radix: ',',
                  mapToRadix: ['.'],
                },
              },
            },
          ]}
          disabled={accountBalance === 0 || totalBeforeBalanceUsage === 0}
        />
        <Typography variant='subtitle2' sx={{ marginTop: '32px' }}>
          Resumo da compra
        </Typography>
        <Divider sx={{ margin: '32px 0' }} />
        <ValuesSummary
          values={[
            {
              label: 'Pedido de recarga',
              value: cart.rechargeValue,
              size: 'md',
              hideZero: cart.rechargeOrders.length < 1,
            },
            {
              label: 'Pedido de cartão - 1ª via',
              value: cart.firstCopyValue,
              size: 'md',
              hideZero: cart.firstCopyOrders.length < 1,
            },
            {
              label: 'Pedido de cartão - 2ª via',
              value: cart.secondCopyValue,
              size: 'md',
              hideZero: cart.secondCopyOrders.length < 1,
            },
            { label: 'Taxas administrativas', value: cart.administrateFee, size: 'md', hideZero: true },
            { label: 'Taxa de repasse', value: cart.transferFeeValue, size: 'md', hideZero: true },
            features.ticketingBalance && cart?.ticketingBalanceValue > 0
              ? {
                  label: 'Conta corrente total',
                  value: cart.ticketingBalanceValue,
                  size: 'md',
                  hideZero: true,
                  isNegative: true,
                  color: 'green',
                }
              : null,
            {
              label: 'Retenção ISS',
              value: cart.issFeeValue,
              size: 'md',
              hideZero: true,
              isNegative: true,
              color: 'green',
            },
            {
              label: account?.resale?.digitalAccountName,
              value: balanceToBeUsed,
              size: 'md',
              isNegative: true,
              color: 'green',
            },
          ]}
        />
        <Divider sx={{ margin: '32px 0' }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', fontWeight: 600 }}>
          <Typography sx={{ fontWeight: 600 }}>Total a ser pago: </Typography>
          <Typography variant='h4' sx={{ fontWeight: 600 }}>
            <span style={{ fontWeight: 400 }}>R$ </span> {formatMoney(total)}
          </Typography>
        </Box>
        {hasRecharges && (
          <Box
            sx={(theme) => ({
              border: '1px solid ' + theme.palette.primary.main,
              borderRadius: '4px',
              padding: '8px 22px',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              marginTop: '22px',
              '& label': {
                marginBottom: 0 + '!important',
                color: theme.palette.primary.main,
                fontSize: '18px',
                width: '100%',
              },
              '& b': {
                margin: 'auto',
                whiteSpace: 'wrap',
              },
              'input, label': notAllowProjection
                ? {
                    cursor: 'not-allowed',
                  }
                : {
                    cursor: 'pointer',
                  },
            })}
          >
            <CheckboxField
              label={<b>Quero fazer a projeção de saldo</b>}
              onChange={(e) =>
                notAllowProjection
                  ? push({
                      content:
                        'Não é possível realizar a projeção de saldo em pedidos que utilizam saldo da conta corrente bilhetagem',
                      type: EMessageType.Error,
                    })
                  : setShouldUseProjection(e.target.checked)
              }
              value={notAllowProjection ? false : shouldUseProjection}
            />
          </Box>
        )}
        <Button
          variant='contained'
          color='success'
          fullWidth
          sx={{ color: 'white', marginTop: '32px' }}
          disabled={features.methodPayments ? !method : null}
          onClick={() =>
            confirmCart.mutate({
              payload: {
                amountWallet: Number(balanceToBeUsed),
                useBalanceProjection: notAllowProjection ? false : shouldUseProjection,
                paymentType: ORDER_PAYMENT_TYPE[method],
              },
            })
          }
        >
          Concluir pedido
        </Button>
      </Paper>
    )
}
