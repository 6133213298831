import { useContext } from 'react'
import _ from 'lodash'
import { Box, Grid, Tooltip, Typography } from 'presentation/components/atoms'
import { Item, ItemHeader, ItemLabel, ItemValuesContainer } from './styles'
import { formatMoney } from 'main/utils/functions'
import { TOrderItem } from 'main/interfaces'
import { OrderTrackingContext } from 'presentation/pages/orderTracking/orderTracking'
import { BENEFIT_TYPE, BENEFIT_TYPE_KEY } from 'main/utils/constants'
import { InfoCircle } from '@/presentation/assets/icons/infoCircle'
import { Icon } from '@stationkim/front-ui'
import { faCircleExclamation, faInfo } from '@fortawesome/free-solid-svg-icons'

const GridValue = ({
  label,
  value,
  isMonetary,
  blocked,
}: {
  blocked?: boolean
  label: string
  value: string | number
  isMonetary?: boolean
}) => {
  return (
    <Grid item xs='auto' key={_.uniqueId()}>
      <ItemValuesContainer>
        <ItemLabel variant='text2' sx={{ whiteSpace: 'nowrap' }}>
          {label}
        </ItemLabel>
        <Typography variant='label2' sx={{ fontWeight: 400, whiteSpace: 'nowrap' }} id='value'>
          {isMonetary ? `R$ ${formatMoney(Number(value))}` : value}
          {blocked ? (
            <Tooltip
              placement='top'
              title={
                <>
                  <p>
                    O valor que seria utilizado para esta recarga foi reembolsado. <br />
                    Motivo: O cartão selecionado encontra-se com status <b>Bloqueado.</b>
                  </p>
                </>
              }
            >
              <div>
                <InfoCircle />
              </div>
            </Tooltip>
          ) : null}
        </Typography>
      </ItemValuesContainer>
    </Grid>
  )
}

export const OrderItem = ({ orderItem }: { orderItem: TOrderItem }) => {
  const { isRecharge } = useContext(OrderTrackingContext)

  return (
    <Box>
      <ItemHeader>
        <Typography>{orderItem.employeeRegistration}</Typography>
        <Typography>{orderItem.employeeName}</Typography>
      </ItemHeader>
      <Item
        container
        spacing='8px'
        justifyContent='space-between'
        blocked={orderItem?.status === 12 || orderItem?.status === 13}
      >
        {isRecharge ? (
          <GridValue label='Produto' value={BENEFIT_TYPE[orderItem.benefitType as BENEFIT_TYPE_KEY]} />
        ) : (
          <GridValue label='Nome Cartão' value={orderItem.operatorCardName} />
        )}
        <GridValue label='N° Cartão' value={orderItem.cardNumber} />
        <GridValue label='Mat. Operadora' value={orderItem.operatorRegistration} />
        {isRecharge ? (
          <>
            <GridValue label='Dias' value={orderItem.days} />
            <GridValue label='Valor dia' value={orderItem.dayValue} isMonetary />
          </>
        ) : (
          <GridValue label='Operadora' value={orderItem.operatorName} />
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'end',
          }}
        >
          <GridValue
            label='Total'
            value={orderItem.total}
            isMonetary
            blocked={orderItem?.status === 12 || orderItem?.status === 13}
          />
          {orderItem?.isTotalValueDiscounted && isRecharge && (
            <Tooltip
              title='O valor solicitado de recarga foi 0, pelo motivo de ter sido realizado a projeção de saldo. O valor que seria utilizado para esta recarga foi reembolsado'
              placement='top'
            >
              <Icon
                icon={faCircleExclamation}
                sx={{
                  cursor: 'help',
                  margin: '0 8px 12px 0',
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Item>
    </Box>
  )
}
