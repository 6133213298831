import { Table } from 'presentation/components'
import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  position: sticky;
  top: 0px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;

  > span {
    font-weight: var(--font-weight-semi-bold);
  }
`

export const CustomTable = styled(Table)`
  border-bottom: 1px solid var(--color-secundary-04);

  label,
  span {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
  }

  div {
    max-height: initial;
  }

  th {
    display: flex;
    align-items: center;
    gap: 32px;

    div {
      display: flex;
      flex-direction: column;

      span {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  th + th {
    text-align: right;
  }

  tr:last-child {
    border: none;
  }

  tr:hover {
    background-color: var(--color-primary-05);
  }

  table tr:hover {
    background-color: var(--color-secundary-04);
  }

  > tbody > tr > td[colspan='2'] {
    padding: 0;
  }
`

export const CheckBox = styled.label`
  border: 2px solid var(--color-primary-01);
  border-radius: 4px;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border-color: var(--color-primary-01);
    `};

  input {
    opacity: 0;
  }

  input:checked ~ div {
    background-color: var(--color-primary-01);
    border-radius: 4px;
    min-height: 0.875rem;
    min-width: 0.875rem;
    position: absolute;
    left: 3px;
    top: 3px;
  }

  input:disabled ~ div,
  input:disabled {
    cursor: not-allowed;
    background-color: var(--color-secundary-04);
  }
`
