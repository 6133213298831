import React from 'react'

import pixIcon from '@/presentation/assets/icons/pix-payment.svg'
import pixCheckedIcon from '@/presentation/assets/icons/pix-payment-checked.svg'
import boletoIcon from '@/presentation/assets/icons/boleto-payment.svg'
import boletoCheckedIcon from '@/presentation/assets/icons/boleto-payment-checked.svg'
import termBillIcon from '@/presentation/assets/icons/term-bill.svg'
import termBillCheckedIcon from '@/presentation/assets/icons/term-bill-checked.svg'

import { Container, Tag } from './styles'

interface MethodPaymentProps {
  name: 'Pix' | 'Boleto' | 'Boleto a Prazo'
  onClick: () => void
  checked: boolean
}

export const MethodPayment: React.FC<MethodPaymentProps> = ({ name, onClick, checked }) => {
  const icon = {
    Pix: checked ? pixCheckedIcon : pixIcon,
    Boleto: checked ? boletoCheckedIcon : boletoIcon,
    'Boleto a Prazo': checked ? termBillCheckedIcon : termBillIcon,
  }

  return (
    <Container onClick={onClick} pulse={name === 'Pix'} checked={checked}>
      {name === 'Pix' ? <Tag>NOVO</Tag> : null}
      <div>
        <img src={icon[name as keyof typeof icon]} alt='paymentIcon' />
        <p>{name}</p>
      </div>
    </Container>
  )
}
