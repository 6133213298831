import React, { useEffect, useState } from 'react'
import { ModalHeader, ModalBody, ModalTitle, ModalContainer, ModalFooter } from '../../../style'
import { Button, Icon, Input, Table } from 'presentation/components'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { getEmployeesWithoutCard } from '@/services/employeesServices'
import { Search } from 'presentation/components/molecules/search'
import useDebounce from 'main/hooks/useDebouce'
import useService from 'main/hooks/useService'
import { pushNotification } from 'store/modules/notification/actions'
import successHandler from 'utils/successHandler'
import { useDispatch } from 'react-redux'
import { patchAssociateCard } from 'services/cardsServices'

const AssociateemployeeModal = ({ isOpen, onClose, cardId }) => {
  const dispatch = useDispatch()
  const debounce = useDebounce()
  const [selected, setSelected] = useState(null)
  const [textFilter, setTextFilter] = useState('')

  const [availableEmployeesState, availableEmployeesRequest] = useService(getEmployeesWithoutCard)
  let itemsToRender = availableEmployeesState?.response?.data || []
  const sortByKey = (key) => (a, b) => a[key] > b[key] ? 1 : -1
  itemsToRender = itemsToRender.slice().sort(sortByKey('name'))

  itemsToRender = itemsToRender.filter(
    (item) =>
      String(item.name).toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()) ||
      String(item.registration).toLocaleLowerCase().includes(textFilter.toLocaleLowerCase()),
  )

  const [associationState, associationRequest] = useService(patchAssociateCard, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Cartão associado ao colaborador!')))
      onClose(true)
    },
  })

  const isLoading = associationState.loading || availableEmployeesState.loading

  useEffect(() => {
    availableEmployeesRequest(cardId)
    // eslint-disable-next-line
  }, [])

  const closeIcon = <Icon name='x' />
  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        open={isOpen}
        onClose={onClose}
        center
        closeIcon={closeIcon}
        styles={{ modal: { width: '650px', height: '550px', maxWidth: '100%', margin: '0', maxHeight: '100%' } }}
      >
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>Colaboradores disponíveis para associar</ModalTitle>
          </ModalHeader>
          <div> Selecione na tabela abaixo um colaborador que será associado a esse cartão.</div>
          <Search
            placeholder='Buscar por nome ou matrícula'
            onSearch={(value) => debounce(() => setTextFilter(value), 300)}
            searchOnChange
          />
          <ModalBody>
            <Table loading={isLoading}>
              <tbody>
                <Table.Th style={{ width: '10%' }}></Table.Th>
                <Table.Th style={{ width: '65%' }}>Colaborador</Table.Th>
                <Table.Th style={{ width: '25%' }}>Matrícula</Table.Th>
                {itemsToRender.length > 0 ? (
                  itemsToRender.map((employee) => (
                    <Table.Tr key={employee?.id}>
                      <Table.Td>
                        <Input.CheckBox
                          checked={selected?.id === employee?.id}
                          controlled
                          onClick={(e) => setSelected(() => (selected?.id !== employee?.id ? employee : null))}
                        />
                      </Table.Td>
                      <Table.Td>{employee?.name}</Table.Td>
                      <Table.Td>{employee?.registration}</Table.Td>
                    </Table.Tr>
                  ))
                ) : (
                  <Table.Tr>
                    <Table.Td colSpan={3} style={{ textAlign: 'center' }}>
                      Sem colaboradores disponíveis
                    </Table.Td>
                  </Table.Tr>
                )}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button width='250px' onClick={onClose}>
              Cancelar
            </Button>
            <Button
              width='250px'
              onClick={() => associationRequest(cardId, selected.id)}
              disabled={!selected || isLoading}
              variant='contained'
              sx={{ width: '248px' }}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContainer>
      </Modal>
    </>
  )
}

export default AssociateemployeeModal
