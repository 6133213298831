import { Box, Breadcrumb, Divider, Typography } from 'presentation/components/atoms'

interface PageHeaderProps {
  pageTitle: string
  noDivider?: boolean
  useTitleAsBreadcrumb?: boolean
  padding?: string
}

export const PageHeader = ({
  pageTitle,
  noDivider,
  padding = '16px 16px 0 16px',
  useTitleAsBreadcrumb = true,
}: PageHeaderProps) => {
  return (
    <Box sx={{ padding: padding }}>
      <Typography variant='h1'>{pageTitle}</Typography>
      <Breadcrumb lastItemTitle={useTitleAsBreadcrumb ? pageTitle : null} />
      {!noDivider && <Divider overflow sx={{ marginTop: '16px' }} />}
    </Box>
  )
}
