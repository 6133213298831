import { Panel, Spacing } from 'presentation/components'
import { Search } from '@/presentation/components/molecules'

import { TitlePanel } from './styles'

const ListPanel = ({ children, filter, setFilter, title }) => {
  return (
    <Panel
      style={{
        padding: '24px',
        height: '630px',
        maxWidth: '100%',
      }}
    >
      <div style={{ overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <TitlePanel>{title}</TitlePanel>
        <div style={{ marginTop: '15px', width: '70%' }}>
          <Search placeholder='Buscar na lista abaixo' value={filter} onChange={(e) => setFilter(e)} />
        </div>
        <Spacing top='32px' />
        {children}
      </div>
    </Panel>
  )
}

export default ListPanel
