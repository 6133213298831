import { Table } from 'presentation/components'
import styled, { css } from 'styled-components'

export const Label = styled.label`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: var(--font-weight-medium);
  position: relative;
`

export const GroupName = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
  cursor: pointer;

  svg {
    cursor: pointer;
    margin-left: 8px;
    height: 8px;
    width: 8px;
    fill: black;
    transform: ${({ visible }) => (visible ? '' : 'scaleY(-1)')};
  }
`

export const GroupContentTr = styled(Table.Tr)`
  display: ${({ visible }) => (visible ? 'auto' : 'none')};
  overflow: hidden;
`

export const CustomRow = styled(Table.Tr)`
  span {
    margin-left: 40px;
  }

  input {
    padding-left: 40px;
  }

  .employee {
    font-size: 12px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      td {
        color: var(--color-secundary-06);
      }

      label,
      input {
        border-color: var(--color-secundary-06);
        cursor: not-allowed;
      }
    `}
`

export const GroupRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const CheckBox = styled.label`
  border: 2px solid var(--color-primary-01);
  border-radius: 4px;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      border-color: rgba(0, 0, 0, 0.54);
    `};

  input {
    opacity: 0;
  }

  input:checked ~ div {
    background-color: var(--color-primary-01);
    border-radius: 4px;
    min-height: 0.875rem;
    min-width: 0.875rem;
    position: absolute;
    left: 3px;
    top: 3px;
  }

  input:disabled ~ div,
  input:disabled {
    cursor: not-allowed;
    background-color: var(--color-secundary-04);
  }
`
