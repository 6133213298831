import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import {
  Box,
  Button,
  Form,
  FormFileUpload,
  FormSelect,
  Grid,
  Icon,
  Skeleton,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
  CircularProgress,
} from '@stationkim/front-ui'
import { download } from '@/utils/functionUtils'
import { NewOrderContainer, OrderLink } from './styles'
import { BrokerHOC } from '../brokerHOC'
import { IUseBrokerOrderReturn } from './hooks/shared'

export const BrokerImport = ({
  integration: { lastOrder, redoLastOrder, layoutOptions, isLoading, uploadImportFile },
}: {
  integration: IUseBrokerOrderReturn
}) => {
  const [howToOrder, setHowToOrder] = useState<'repeatOrder' | 'newOrder'>('newOrder')
  const isNewOrder = howToOrder === 'newOrder'

  const form = useForm({ defaultValues: { operatorId: '', files: null } })

  const [operatorId] = form.watch(['operatorId'])
  const operator = layoutOptions.find((option) => option.id === operatorId)

  const navigate = useNavigate()

  const isContinueDisabled =
    isLoading.upload ||
    (isLoading.lastOrder && howToOrder === 'repeatOrder') ||
    (isLoading.options && howToOrder === 'newOrder') ||
    isLoading.redoing

  const downloadFile = async ({ operator }) => {
    const response = await axios({
      url: operator.modelLink,
      method: 'GET',
      responseType: 'blob',
    })
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
    download(fileUrl, `Modelo de arquivo para pedido de recarga broker - ${operator.label}.xlsx`)
  }

  const handleSubmit = (data) => {
    if (!isNewOrder) redoLastOrder.mutate({ orderId: lastOrder.importationId || lastOrder.id })
    else {
      const formData = new FormData()
      formData.append('files', data.files)
      uploadImportFile.mutate(formData)
    }
  }

  return (
    <BrokerHOC>
      {isLoading.redoing || isLoading.upload ? (
        <Box
          sx={{ padding: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '320px' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ padding: '32px' }}>
          <Typography sx={{ fontWeight: 500 }}>Selecione o tipo de pedido</Typography>
          <RadioGroup
            value={howToOrder}
            onChange={(e) => setHowToOrder(e.target.value as any)}
            sx={{ margin: ' 0 0 0px 16px' }}
          >
            <FormControlLabel value='newOrder' control={<Radio />} label='Importar novo pedido' />
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <FormControlLabel
                value='repeatOrder'
                control={<Radio />}
                label='Repetir o pedido'
                sx={{ marginRight: '8px' }}
                disabled={isLoading.lastOrder || !lastOrder}
              />
              <OrderLink href={`/recargas/acompanhamento#orderId="${lastOrder?.id}"`} target='_blank' rel='noreferrer'>
                {isLoading.lastOrder || !lastOrder ? (
                  <Skeleton variant='rounded' width='60px' />
                ) : (
                  `#${lastOrder?.code}`
                )}
              </OrderLink>
            </Box>
          </RadioGroup>
          <Divider sx={{ margin: '24px 0px' }} />
          <NewOrderContainer isNewOrder={isNewOrder}>
            <Typography variant='body2' sx={{ marginBottom: '16px' }}>
              Solicite uma recarga através do arquivo de importação. Baixe um modelo de arquivo, preencha com as
              informações solicitadas respeitando os campos.
              <br /> Em seguida salve o arquivo e faça a importação.
            </Typography>
            <Form id='orderImportForm' form={form} onSubmit={handleSubmit} gridProps={{ spacing: '16px' }}>
              {isLoading.options ? (
                <Grid item xs={12} sm={6} sx={{ marginTop: '32px' }}>
                  <Skeleton variant='rounded' height='48px' width='100%' fullWidth />
                </Grid>
              ) : (
                <FormSelect
                  label='Selecione o modelo'
                  placeholder='Selecione...'
                  name='operatorId'
                  options={layoutOptions}
                  getOptionValue={(option) => option.id}
                  disabled={isLoading.options}
                  rules={isNewOrder ? { required: 'É preciso selecionar uma operadora' } : { required: false }}
                  gridProps={{ xs: 12, sm: 6, md: 5, lg: 3 }}
                />
              )}
              <Grid item xs={12} sm={6} md='auto'>
                <Button
                  type='button'
                  variant='contained'
                  sx={{ marginTop: { xs: 0, sm: '32px' } }}
                  startIcon={<Icon icon={faArrowRightToBracket} sx={{ transform: 'rotate(90deg)' }} />}
                  onClick={() => downloadFile({ operator })}
                  fullWidth
                  disabled={!operator}
                >
                  Baixar Modelo Excel (XLSX)
                </Button>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '16px' }}>
                <FormFileUpload
                  name='files'
                  rules={
                    isNewOrder
                      ? { required: 'Para prosseguir é preciso anexar o arquivo preenchido' }
                      : { required: false }
                  }
                  accept={{ accept: ['.xlsx'] }}
                  maxSize={3150000}
                />
              </Grid>
            </Form>
          </NewOrderContainer>
          <Grid container spacing='16px'>
            <Grid item xs={12} sm={6} md='auto' sx={{ marginLeft: 'auto' }}>
              <Button variant='outlined' onClick={() => navigate('/recargas')} fullWidth>
                Voltar
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md='auto'>
              <Button variant='contained' form='orderImportForm' type='submit' fullWidth disabled={isContinueDisabled}>
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </BrokerHOC>
  )
}
