import { Input, TextDecoration } from 'presentation/components'
import { useState } from 'react'
import { Dialog } from '@mui/material'

import {
  ModalInputsContainer,
  ModalInputWrapper,
  ModalLabel,
  ModalTextContainer,
  DialogContent,
  ButtonsContainer,
} from './styles'
import { Button } from '@/presentation/components/atoms'

const GlobalRechargeModal = ({ initialValues, open, onClose, onConfirm }) => {
  const [days, setDays] = useState(initialValues.days)
  const [value, setValue] = useState(initialValues.value)

  return (
    // <ModalV2.TwoOptions
    //   modalId={modalId}
    //   open={open}
    //   leftText='Cancelar'
    //   rightText='Confirmar'
    //   onLeftClick={onClose}
    //   onRightClick={() => (days !== '' || value !== '' ? onConfirm({ days, value }) : null)}
    //   onClose={onClose}
    //   closeOnEsc={false}
    //   closeOnOverlayClick={false}
    //   showCloseIcon={false}
    // >
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <ModalTextContainer>
          Igualar a quantidade de <TextDecoration>dias</TextDecoration> e/ou <TextDecoration>valor</TextDecoration> para
          todos os colaboradores?
        </ModalTextContainer>
        <ModalInputsContainer>
          <ModalInputWrapper>
            <ModalLabel>Qtd dias</ModalLabel>
            <Input.Masked
              placeholder='0'
              value={days}
              onChange={(e) => setDays(e.target.value)}
              disabled={value !== ''}
            />
          </ModalInputWrapper>
          <ModalInputWrapper>
            <ModalLabel>Valor (R$)</ModalLabel>
            <Input.Decimal
              placeholder='00,00'
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={days !== ''}
            />
          </ModalInputWrapper>
        </ModalInputsContainer>
        <ButtonsContainer>
          <Button variant='outlined' onClick={() => onClose()}>
            Cancelar
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              if (days !== '' || value !== '') {
                onConfirm({ days, value })
                onClose()
              }
            }}
          >
            Confirmar
          </Button>
        </ButtonsContainer>
      </DialogContent>
    </Dialog>
  )
}

export default GlobalRechargeModal
