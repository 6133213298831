import { useEffect } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useAtomValue } from 'jotai'
import {
  TransferList,
  Content,
  Spacing,
  Steps,
  TextDecoration,
  PageChangePrompt,
  Panel,
  PageHeader,
  Spinner,
} from 'presentation/components'
import { Button } from '@/presentation/components/atoms'
import { ButtonWrapper, StepsWrapper } from './styles'

import { TEMP_OPERATOR_ID } from '@/utils/constants'
import { orderIdAtom } from 'main/store'
import api from '@/infra/api'
import { useDispatch } from 'react-redux'
import { useOrder } from './hooks/useOrder'
import { useNavigate } from 'react-router-dom'

const RechargeEmployeesSelection = () => {
  const navigate = useNavigate()
  const orderIdToEdit = useAtomValue(orderIdAtom)

  const {
    cancelOrder,
    createOrder,
    loading,
    selecteds,
    setInitialState,
    setLoading,
    setSelecteds,
    changeEmployeesInOrder,
    setUnselecteds,
    unselecteds,
  } = useOrder({ orderIdToEdit })

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        let query = orderIdToEdit ? `orderId=${orderIdToEdit}` : `operatorId=${TEMP_OPERATOR_ID}`
        const response = await api.get(`rh-bff/recharge/new/employees?${query}`)
        const processed = convertDataToComponentStructure(response.data)

        if (processed) {
          setUnselecteds(processed.filter((employee) => employee.selected === false))
          setSelecteds(processed.filter((employee) => employee.selected === true))
          setInitialState(processed.filter((employee) => employee.selected === true))
        }
      } catch (error) {
        return
      }
      setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orderIdToEdit])

  const convertDataToComponentStructure = (data) => {
    return data.reduce((employees, actualDepartment) => {
      let employeesFromDepartment = ''
      if (actualDepartment.employees)
        employeesFromDepartment = actualDepartment.employees.map((employee) => ({
          ...employee,
          departmentId: actualDepartment.department ? actualDepartment.department.id : 'without-department',
          departmentName: actualDepartment.department ? actualDepartment.department.name : 'Sem departamento',
        }))

      return [...employees, ...employeesFromDepartment]
    }, [])
  }

  const steps = [
    {
      name: 'Colaboradores',
      navigable: false,
    },
    {
      name: 'Informações',
      navigable: false,
    },
    {
      name: 'Pagamento',
      navigable: false,
    },
  ]

  return (
    <>
      {orderIdToEdit && (
        <PageChangePrompt onlyConfirm>
          <p>Você não finalizou seu pedido.</p>
          <p>
            Seu pedido será salvo como <TextDecoration>Rascunho</TextDecoration> por uma semana e você poderá continuar
            de onde parou para finalizá-lo.
          </p>
        </PageChangePrompt>
      )}
      <Content>
        <Panel noPadding>
          <PageHeader>Novo pedido manual</PageHeader>
          <StepsWrapper>
            {loading ? (
              <Spinner.Box>
                <Spinner />
              </Spinner.Box>
            ) : (
              <Steps currentStep={1} steps={steps} />
            )}
          </StepsWrapper>
        </Panel>
        {!loading && (
          <>
            <Spacing top='32px' />
            <Container style={{ padding: 0, maxWidth: 'auto' }}>
              <Row nogutter>
                <Col>
                  <TransferList.Grouped
                    rightList={selecteds}
                    rightName='Colaboradores e setores selecionados'
                    setRight={setSelecteds}
                    leftList={unselecteds}
                    leftName='Selecionar colaboradores/setores'
                    setLeft={setUnselecteds}
                    displayName='name'
                    groupBy={(item) => item.departmentName || 'Sem departamento'}
                    blockGroups={[]}
                    loading={loading}
                  />
                </Col>
              </Row>
              <Row nogutter>
                <Col sm={12}>
                  <ButtonWrapper>
                    {orderIdToEdit ? (
                      <Button variant='outlined' onClick={cancelOrder}>
                        Cancelar
                      </Button>
                    ) : (
                      <Button variant='outlined' onClick={() => navigate('/recargas')}>
                        Voltar
                      </Button>
                    )}

                    <Button
                      variant='contained'
                      onClick={() => {
                        if (selecteds.length === 0) return
                        !orderIdToEdit && createOrder()
                        orderIdToEdit && changeEmployeesInOrder()
                      }}
                    >
                      Próximo
                    </Button>
                  </ButtonWrapper>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Content>
    </>
  )
}

export default RechargeEmployeesSelection
