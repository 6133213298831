import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { QRCodeSVG } from 'qrcode.react'

import { status } from './utils/status'
import { Content } from 'presentation/components'
import { Divider, Grid, Paper, Button, Box, Tooltip } from 'presentation/components/atoms'
import { Items } from './components/Items/Items'
import { Stepper } from './components/Stepper/Stepper'
import { Toast } from './components/Toast/Toast'
import { formatMoney } from 'utils/functionUtils'

import copy from '@/presentation/assets/icons/copy.png'
import loadingCopy from '@/presentation/assets/icons/spinner-pix-copy.svg'
import qrcodeFinished from '@/presentation/assets/icons/qrcode-finished.svg'
import checkCircleGreen from '@/presentation/assets/icons/check-circle-green.svg'
import cancelRecharge from '@/presentation/assets/icons/cancel-recharge.svg'
import loadingPix from '@/presentation/assets/icons/loading-pix.png'

import { Details, Steps, Total, Title, Clipboard, Order, PaymentFinished } from './styles'
import { OrderCancelationModal } from '@/presentation/components/molecules/orderCancelationModal/orderCancelationModal'
import { Skeleton } from '@stationkim/front-ui'

export const Pix = ({ data, orders, loading, title, cancelOrder, orderId, paymentFinished }) => {
  const [isCopyTipOpen, setIsCopyTipOpen] = useState(false)
  const [isCancel, setIsCancel] = useState(false)

  const copyText = () => {
    navigator.clipboard.writeText(data?.pix?.qrCode)
    const element: any = document.querySelector('#rawPix')
    element.select()
  }

  useEffect(() => {
    setTimeout(() => {
      setIsCopyTipOpen(false)
    }, 1000)
  }, [isCopyTipOpen])

  const isLoading = !data?.pix?.qrCode || loading

  const toast =
    data?.confirmation?.totalAmountPayable === 0
      ? `Parabéns! O pedido foi realizado com sucesso. Para este pedido você utilizou R$ ${Intl.NumberFormat(
          'BRL',
        ).format(data?.confirmation?.useOfWallet)} da sua carteira digital.`
      : 'Pedido gerado! Efetue o pagamento para avançar para a próxima etapa'
  return (
    <>
      <Content>
        <Paper sx={{ padding: '32px' }}>
          <Toast title={toast} />
          <Grid container justifyContent='space-between'>
            <Order>
              <li>
                Pedido {title} #{data?.code}
              </li>
              {!data ? null : (
                <>
                  <p>⚬</p>
                  <li>
                    {new Date(data?.orderDate).toLocaleDateString() === 'Invalid Date'
                      ? '-'
                      : new Date(data?.orderDate).toLocaleDateString()}
                  </li>
                  <p>⚬</p>
                  <li>R$ {formatMoney(data?.confirmation?.totalAmountPayable || data?.confirmation?.subTotalValue)}</li>
                </>
              )}{' '}
            </Order>
          </Grid>
          <Divider sx={{ margin: '32px 0' }} />
          <Title>Pagamento</Title>
          <Details>
            {paymentFinished ? (
              <PaymentFinished>
                <h5>
                  {data?.isCanceled ? (
                    `Seu pedido foi cancelado em ${data?.cancelDate}`
                  ) : (
                    <>
                      'Pagamento confirmado'
                      <img src={checkCircleGreen} height={20} width={20} alt='check circle green' />
                    </>
                  )}
                </h5>
                <Box
                  sx={(theme) => ({
                    margin: '24px 0',
                    '& span': data?.isCanceled
                      ? { textDecoration: 'line-through', color: theme.palette.grey[500], opacity: 0.8 }
                      : {},
                  })}
                >
                  <p>
                    Valor:{' '}
                    <span>
                      R$ {formatMoney(data?.confirmation?.totalAmountPayable || data?.confirmation?.subTotalValue)}
                    </span>
                  </p>
                </Box>
                <Box className='qrcode-finished'>
                  <img src={qrcodeFinished} alt='qrcode' />
                  {data?.isCanceled ? (
                    <img
                      src={cancelRecharge}
                      alt='canceled pix'
                      style={{ borderRadius: '50%', transform: 'scale(4)', backgroundColor: 'white' }}
                    />
                  ) : (
                    <img src={checkCircleGreen} alt='confirmed pix' />
                  )}
                </Box>
              </PaymentFinished>
            ) : (
              <>
                {isLoading ? (
                  <h5>Aguarde, gerando QRCode...</h5>
                ) : (
                  <>
                    <h4>Escaneie o código para efetuar o pagamento</h4>
                    <Steps>
                      <li>1. Acesse o app de pagamentos</li>
                      <li>2. Escolha pagamento via PIX</li>
                      <li>3. Escaneie o código:</li>
                    </Steps>
                  </>
                )}
                <Total>
                  Valor:{' '}
                  <span>
                    R$ {formatMoney(data?.confirmation?.totalAmountPayable || data?.confirmation?.subTotalValue)}
                  </span>
                </Total>
                <Box className='qrcode-finished'>
                  {isLoading ? (
                    <>
                      <img src={qrcodeFinished} alt='qrcode' />
                      <img src={loadingPix} alt='loading pix' className='loading' />
                    </>
                  ) : (
                    <QRCodeSVG width={200} height={200} value={data?.pix?.qrCode} />
                  )}
                </Box>
                <Divider sx={{ margin: '32px 0' }} />
                <h5>Ou copie o código QR para efetuar o pagamento</h5>
                <p>Escolha o pagamento via PIX no seu app de pagamentos. Cole o seguinte código no campo informado:</p>
                <Clipboard>
                  <input
                    readOnly
                    value={isLoading ? 'Aguarde até que o código seja gerado...' : data?.pix?.qrCode}
                    id='rawPix'
                  />
                  {isLoading ? (
                    <img src={loadingCopy} alt='loading-copy' className='loading' />
                  ) : (
                    <Tooltip
                      title='Código copiado'
                      placement='top'
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      open={isCopyTipOpen}
                    >
                      <img
                        src={copy}
                        alt='copy'
                        onClick={() => {
                          copyText()
                          setIsCopyTipOpen(true)
                        }}
                      />
                    </Tooltip>
                  )}
                </Clipboard>
                {!isLoading ? (
                  <Button
                    variant='contained'
                    onClick={() => {
                      copyText()
                      setIsCopyTipOpen(true)
                    }}
                  >
                    Copiar código
                  </Button>
                ) : null}
              </>
            )}
          </Details>
          {data?.isCanceled ? null : (
            <>
              <Divider sx={{ margin: '32px 0' }} />
              <Title>Pedido</Title>
              {!data ? (
                <Skeleton variant='rounded' height='200px' />
              ) : (
                <Details>
                  <Stepper step={status[data?.status]} />
                  {orders.map((order, index) => {
                    return (
                      <Items
                        key={'pixItem_' + index}
                        title={title}
                        subtitle={`${order?.totalItens} colaboradores`}
                        order={`#${order?.code || '-'}`}
                        operator={`Operadora: ${order?.operatorCardName || '-'}`}
                        orderType='recharge'
                        price={order?.totalRechargeValue}
                        orders={[order]}
                      />
                    )
                  })}
                </Details>
              )}
            </>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
            {!data?.isCanceled && (
              <Button
                variant='outlined'
                style={{ color: '#D32F2F', borderColor: '#D32F2F' }}
                onClick={() => setIsCancel(true)}
                disabled={!data?.pix?.qrCode || data?.status > 3}
              >
                Cancelar Pedido
              </Button>
            )}
            <Link to='/recargas' style={{ marginLeft: 'auto' }}>
              <Button variant='outlined'>Continuar</Button>
            </Link>
          </Box>
        </Paper>
      </Content>
      <OrderCancelationModal
        open={isCancel}
        orders={[]}
        onCancel={() => setIsCancel(false)}
        onConfirm={() => cancelOrder.mutate({ orderId })}
        isLoading={false}
      />
    </>
  )
}
