import { useLocation } from 'react-router-dom'
import { IntegrationSwapper } from 'presentation/components'
import { PaymentConfirmation as PaymentConfirmationComponent } from './paymentConfirmation'
import { useOrder } from './hooks/useOrder'
import { OldPaymentConfirmation } from './oldPaymentConfirmation/oldPaymentConfirmation'
import { useState } from 'react'

const NewAPIIntegration = () => {
  const { isLoading: isLoadingActions, confirmOrder, cancelOrder, data, details } = useOrder()
  const isLoading = isLoadingActions
  const location = useLocation()
  const purchaseId = location.state?.purchaseId
  const [days, setDays] = useState()
  return (
    <PaymentConfirmationComponent
      data={data}
      details={details}
      cancelOrder={async () => await cancelOrder()}
      confirmOrder={(args) => confirmOrder({ ...args, purchaseId, days })}
      removeAsSoonAsPossibleDays={setDays}
      isLoading={isLoading}
    />
  )
}

export const PaymentConfirmation = () => {
  const location = useLocation()
  return (
    <IntegrationSwapper
      isDealer={location?.state?.isDealer}
      components={{
        ifIsMotiva: <OldPaymentConfirmation />,
        ifIsNotMotiva: <NewAPIIntegration />,
      }}
    />
  )
}
