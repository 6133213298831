import React from 'react'

import { Radio } from '@mui/material'

import pixIcon from '@/presentation/assets/icons/pix.svg'
import boletoIcon from '@/presentation/assets/icons/boleto-icon.png'

import { Container } from './styles'

interface MethodPaymentProps {
  name: 'Pix' | 'Boleto'
  onClick: () => void
  checked: boolean
}

export const MethodPayment: React.FC<MethodPaymentProps> = ({ name, onClick, checked }) => {
  const icon = {
    Pix: pixIcon,
    Boleto: boletoIcon,
  }

  return (
    <Container onClick={onClick}>
      <div>
        <img src={icon[name as keyof typeof icon]} alt='paymentIcon' />
        <p>{name}</p>
      </div>
      <Radio checked={checked} />
    </Container>
  )
}
