import axios from 'axios'
import qs from 'qs'
const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  paramsSerializer: (params) => {
    let newParams = { ...params }
    for (const key in newParams) {
      if ((!newParams[key] || newParams[key]?.length === 0) && newParams[key] !== 0) delete newParams[key]
      if (Array.isArray(newParams[key])) newParams[key] = `[${newParams[key]}]`
    }

    return qs.stringify(newParams)
  },
})

api.interceptors.request.use((config) => {
  const account = JSON.parse(localStorage.getItem('account'))
  if (account?.auth?.accessToken) config.headers.common.Authorization = `Bearer ${account.auth.accessToken}`
  else delete config.headers.common.Authorization
  return config
})

export default api
