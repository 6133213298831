import { useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { Container, Col, useScreenClass } from 'react-grid-system'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

import BlockModal from './components/blockModal'
import AssociateCardModal from './components/associateCardModal/associateCardModal'
import { getCardsRequest } from '@/store/modules/cards/actions'
import { CARD_STATUS } from '@/utils/constants'

import paginatorMethods from '@/utils/paginatorMethods'
import { patchDisassociateCard } from '@/services/cardsServices'

import useService from 'main/hooks/useService'
import { Filter } from 'presentation/components/organisms'
import { getCardsFilterData, patchCardStatus } from 'services/cardsServices'

import { useNotification } from 'main/hooks/useNotification'

import { Pagination, Modal, ModalV2, Table, DropdownButton } from 'presentation/components'
import { Search } from 'presentation/components/molecules'
import { Button } from 'presentation/components/atoms'

import { TableBody } from './components/TableBody/TableBody'
import { TableHead } from './components/TableHead/TableHead'
import { CentralizeText, ColorDest, Row, CustomDropdownButton } from '../../styles'
import { useCards } from 'presentation/pages/cards/tabs/all/hooks/useCards'
import { usePaginationOld } from 'main/hooks/usePaginationOld'
import { useBenefitTypes } from 'main/hooks/useBenefitTypes'

const Options = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [disassociationLoading, setDisassociationLoading] = useState(false)
  const [cardToChangeStatus, setCardToChangeStatus] = useState(null)
  const [cardToBlock, setCardToBlock] = useState(null)
  const [cardIdToDesassociate, setCardIdToDesassociate] = useState(null)
  const [isAssociatingCard, setIsAssociatingCard] = useState(false)
  const [cardId, setCardId] = useState(null)
  const [withoutCollaborator, setWithoutCollaborator] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const query = {
    Sort: 'employeeName',
  }
  const { paginator, setPaginator } = usePaginationOld({ query })

  const { errorNotification, successNotification } = useNotification()
  const { benefitTypes, loading } = useBenefitTypes()
  const { cards, cardsPagination, isLoading } = useCards()

  const screenClass = useScreenClass()

  const [filterData, setFilterData] = useState({
    operatorsCards: [],
    departments: [],
  })

  useMemo(() => {
    if (withoutCollaborator) {
      setTimeout(() => {
        setWithoutCollaborator(false)
      }, 3000)
    }
  }, [withoutCollaborator])

  useEffect(() => {
    paginator.doRequest && dispatch(getCardsRequest(paginatorMethods.buildQuery(paginator)))
    // eslint-disable-next-line
  }, [paginator])

  useEffect(() => {
    if (cardsPagination?.pageSize) {
      setPaginator(
        paginatorMethods.updatePaginator(paginator, {
          ...cardsPagination,
        }),
      )
    }
    // eslint-disable-next-line
  }, [cards])

  const onAssociateCardClose = (isAsoccied) => {
    setIsAssociatingCard(false)
    if (isAsoccied) {
      setPaginator(paginatorMethods.refresh(paginator))
    }
  }

  const [, changeCardStatusRequest] = useService(patchCardStatus, {
    onCompleted: () => {
      successNotification('Status do cartão alterado com sucesso!')
      setPaginator(paginatorMethods.refresh(paginator))
    },
  })

  const changeCardStatus = (card) => {
    if (!card || card.status === CARD_STATUS.BLOQUED) return
    const newStatus = card.status === CARD_STATUS.ACTIVE ? false : true
    changeCardStatusRequest(card.id, newStatus)
    setCardToChangeStatus(null)
  }

  const [fetchFilterValuesState, fetchFilterValuesRequest] = useService(getCardsFilterData, {
    silent: false,
    onCompleted: (res) => {
      const rawFilterValues = res.data

      const cards = rawFilterValues.benefitTypes.reduce((cards, operator) => {
        return [...cards, ...operator?.benefits]
      }, [])

      setFilterData({
        operatorsCards: cards,
      })
    },
  })

  useEffect(() => {
    fetchFilterValuesRequest()
    // eslint-disable-next-line
  }, [])

  async function disassociateCard(cardId) {
    setDisassociationLoading(true)
    const { error, response } = await patchDisassociateCard(cardId)
    if (response) {
      successNotification('Desassociado com sucesso!')
      setPaginator(paginatorMethods.refresh(paginator))
    } else errorNotification(error.response)
    setDisassociationLoading(false)
    setCardIdToDesassociate(null)
  }

  return (
    <Container style={{ padding: 0, maxWidth: '100%' }}>
      <Row justify='flex-start' align='center'>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Search
            onSearch={(value) =>
              setPaginator(
                paginatorMethods.setFilters(paginator, {
                  filterValue: value,
                }),
              )
            }
            id='cards_search'
            placeholder='Buscar na tabela abaixo  ...'
          />
        </Col>
        <Col
          xs={12}
          md={2}
          sm={6}
          style={{
            padding: ['xs', 'sm'].includes(screenClass) ? 15 : 0,
          }}
        >
          <Button
            startIcon={<FontAwesomeIcon icon={faFilter} />}
            variant='outlined'
            onClick={() => setIsFilterOpen(true)}
            fullWidth={['xs'].includes(screenClass)}
          >
            Filtrar
          </Button>
        </Col>
        <Col xs={12} sm={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomDropdownButton
            title='Novo Pedido'
            variant='solid'
            icon={'circle-plus'}
            noArrow
            sx={(theme) => ({ backgroundColor: theme.palette.primary.main })}
          >
            <DropdownButton.Item onClick={() => navigate('/cartoes/novo')}>1ª Via do Cartão</DropdownButton.Item>
            <DropdownButton.Item onClick={() => navigate('/cartoes/novo/segunda')}>
              2ª Via do Cartão
            </DropdownButton.Item>
          </CustomDropdownButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table loading={isLoading}>
            <TableHead setPaginator={setPaginator} paginator={paginator} />
            <TableBody
              cards={cards}
              setCardId={setCardId}
              setIsAssociatingCard={setIsAssociatingCard}
              setCardToBlock={setCardToBlock}
              setCardIdToDesassociate={setCardIdToDesassociate}
              setCardToChangeStatus={setCardToChangeStatus}
            />
          </Table>
        </Col>
      </Row>

      <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'} align='center'>
        <Col xs={12} md='content' style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination.ResultAmount
            totalItems={cardsPagination?.totalItens}
            setPageSize={(newPageSize) => setPaginator(paginatorMethods.setPageSize(paginator, newPageSize))}
          />
        </Col>

        <Col
          xs='content'
          style={{
            marginTop: ['xs', 'sm'].includes(screenClass) ? '16px' : '0',
          }}
        >
          <Pagination
            paginator={paginator}
            setPage={(wantedPage) => setPaginator(paginatorMethods.setPage(paginator, wantedPage))}
          />
        </Col>
      </Row>
      {!!cardToBlock && (
        <BlockModal
          open={!!cardToBlock}
          data={cardToBlock}
          card={cardToBlock}
          onClose={() => setCardToBlock(null)}
          confirmText='Sim, bloquear'
        />
      )}
      {cardToChangeStatus && (
        <Modal
          open={cardToChangeStatus}
          cancel={() => setCardToChangeStatus(null)}
          confirm={() => changeCardStatus(cardToChangeStatus)}
          confirmText={`Sim, ${cardToChangeStatus.status === 1 ? 'inativar' : 'ativar'}`}
        >
          Você tem certeza que deseja <ColorDest>{cardToChangeStatus.status === 1 ? 'inativar' : 'ativar'}</ColorDest>{' '}
          este cartão?
        </Modal>
      )}
      {cardIdToDesassociate && (
        <ModalV2.TwoOptions
          open={true}
          onClose={() => setCardIdToDesassociate(null)}
          onLeftClick={() => setCardIdToDesassociate(null)}
          onRightClick={() => disassociateCard(cardIdToDesassociate)}
          loading={disassociationLoading}
        >
          <CentralizeText>
            Você tem certeza que deseja
            <br />
            desassociar este cartão desse
            <br />
            colaborador?
          </CentralizeText>
        </ModalV2.TwoOptions>
      )}
      <Filter
        open={isFilterOpen}
        isLoading={fetchFilterValuesState.loading || loading}
        onClose={() => setIsFilterOpen(false)}
        onFilter={(value) => {
          const filterValues = Object.keys(value).reduce((final, actual) => {
            const newFinal = { ...final }
            if (value[actual].length !== 0 && value[actual] !== '') newFinal[actual] = value[actual]
            return newFinal
          }, {})
          setPaginator(paginatorMethods.setFilters(paginator, filterValues))
          setIsFilterOpen(false)
        }}
        onClean={() => {
          setPaginator(paginatorMethods.resetFilters(paginator))
          setIsFilterOpen(true)
        }}
        fields={[
          {
            type: 'multiSelect',
            name: 'benefitType',
            props: {
              label: 'Benefícios',
              fullWidth: true,
              variant: 'contained',
              options: benefitTypes,
            },
          },
          {
            type: 'multiSelect',
            name: 'benefitId',
            props: {
              label: 'Nome Cartão',
              fullWidth: true,
              variant: 'contained',
              options: filterData.operatorsCards,
              getOptionLabel: (option) => option.name,
              getOptionValue: (option) => option.id,
            },
          },
          {
            type: 'select',
            name: 'status',
            props: {
              label: 'Status',
              fullWidth: true,
              options: [
                { label: 'Ativo', value: CARD_STATUS.ACTIVE },
                { label: 'Inativo', value: CARD_STATUS.INACTIVE },
                // { label: 'Bloqueado', value: CARD_STATUS.BLOQUED },
              ],
            },
          },
        ]}
      />
      {isAssociatingCard && (
        <AssociateCardModal isOpen={isAssociatingCard} onClose={onAssociateCardClose} cardId={cardId} />
      )}
    </Container>
  )
}

export default Options
