import { useQuery } from '@tanstack/react-query'
import { IFetchHook, IPaginatedRequest } from 'main/interfaces'
import { IOrderBasicInformation } from 'main/interfaces/iOrderBasicInformation'

import api from '@/infra/api'

const defaultValue = {
  data: [],
  page: 1,
  pageSize: 10,
  totalItens: 0,
  totalPages: 1,
}

interface IUseRechargesDataReturn extends IPaginatedRequest<IOrderBasicInformation> {}

export const useRecharges = ({ filter, pagination, immutableQuery }: IFetchHook) => {
  const { data, ...rest } = useQuery({
    queryKey: ['recharges', filter, pagination, immutableQuery],
    queryFn: () =>
      api.get(`rh-bff/recharge/orders`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
  })

  const organizeData = (): IUseRechargesDataReturn => {
    if (!data) return defaultValue
    const organizedData: Array<IOrderBasicInformation> = data.data.data.map(
      (order: any): IOrderBasicInformation => ({
        id: order.id,
        cartId: order.basketId,
        code: order.code,
        orderDate: order.orderDate,
        dueDate: order.dueDate,
        operatorName: order.operatorName,
        status: order.status,
        orderType: order.orderType,
        totalValue: order.totalVale,
        totalItems: order.cardsQuantity,
        paymentType: order?.paymentType,
      }),
    )
    return {
      data: organizedData,
      page: data.data.pageNumber,
      pageSize: data.data.pageSize,
      totalItens: data.data.totalItens,
      totalPages: data.data.totalPages,
    }
  }

  return {
    rechargeOrders: organizeData(),
    ...rest,
  }
}
