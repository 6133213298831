import React, { useEffect, useState } from 'react'
import { Container, Col } from 'react-grid-system'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schema from '../schema/personalData'
import { Input, Button, ErrorMessage, ErrorWrapper } from 'presentation/components'
import { hideDocument } from '@/utils/functionUtils'
import { FieldSet, Label, Row } from './style'
import { checkIfCpfIsInUse } from '../requestApi'
import { states } from '@/utils/options'
import { FORM_MODE } from '@/utils/constants'

const genderOptions = [
  { label: 'Masculino', value: 1 },
  { label: 'Feminino', value: 2 },
  { label: 'Não Binario', value: 3 },
]

const PersonalData = ({ cancel, changeForm, nextTab, mode, returnToList, saveOnStore, employeeData }) => {
  const [isCpfInUse, setIsCpfInUse] = useState(false)
  const readOnly = mode === FORM_MODE.VIEW ? true : false

  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    context: { register: mode === FORM_MODE.ADD ? true : false },
  })

  useEffect(() => {
    reset({
      ...employeeData,
      gender: genderOptions.find((option) => option.value === employeeData?.gender),
      state: states.find((option) => option.value === employeeData?.state),
    })
  }, [employeeData, reset])

  const onSubmit = (data) => {
    if (isCpfInUse) return
    saveOnStore(data)
    nextTab()
  }

  changeForm(() => {
    if (isCpfInUse) return
    const data = getValues()
    handleSubmit(() => {
      saveOnStore(data)
    })()
  })

  async function checkCpfAvailability(cpf) {
    const cpfWithoutMask = cpf?.replace(/ |-|\.|\*/g, '')
    if (!errors.cpf && cpfWithoutMask.length === 11) {
      const response = await checkIfCpfIsInUse(cpfWithoutMask)
      setIsCpfInUse(response.data)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id='personalData'>
        <FieldSet disabled={readOnly}>
          <Container style={{ margin: 0, padding: 0, maxWidth: 'initial' }}>
            <Row>
              <Col sm={12} md={4} lg={4} xl={3}>
                <ErrorWrapper error={errors.cpf || isCpfInUse}>
                  <Label>CPF</Label>
                  <Controller
                    control={control}
                    name='cpf'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Masked
                        placeholder='000.000.000-00'
                        mask={'*'}
                        id='employeeForm_cpf'
                        format={
                          mode === FORM_MODE.ADD || (FORM_MODE.EDIT === mode && !employeeData?.cpf)
                            ? '###.###.###-##'
                            : (actualValue) => hideDocument(actualValue)
                        }
                        value={value}
                        getInputRef={ref}
                        disabled={employeeData?.cpf}
                        onChange={async (e) => {
                          onChange(e)
                          checkCpfAvailability(e.target.value)
                        }}
                      />
                    )}
                  />
                  {errors.cpf && <ErrorMessage>{errors.cpf.message}</ErrorMessage>}
                  {!errors.cpf && isCpfInUse && <ErrorMessage>CPF já cadastrado</ErrorMessage>}
                </ErrorWrapper>
              </Col>
              <Col sm={12} md={4} lg={4} xl={3}>
                <ErrorWrapper error={errors.birthDate}>
                  <Label>Data de Nascimento</Label>
                  <Controller
                    control={control}
                    name='birthDate'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Date id={'employeeForm_birthDate'} value={value} onChange={onChange} getInputRef={ref} />
                    )}
                  />
                  {errors.birthDate && <ErrorMessage>{errors.birthDate.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>

              <Col sm={12} md={4} lg={4} xl={3}>
                <ErrorWrapper error={errors.fullname}>
                  <Label>Nome Completo *</Label>
                  <Input
                    placeholder='Nome Completo'
                    id='employeeForm_fullname'
                    maxLength='120'
                    {...register('fullname')}
                  />
                  {errors.fullname && <ErrorMessage>{errors.fullname.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={3}>
                <ErrorWrapper error={errors.rg}>
                  <Label>RG</Label>
                  <Input name='rg' id='employeeForm_rg' placeholder='0000000000' maxLength='10' {...register('rg')} />
                  {errors.rg && <ErrorMessage>{errors.rg.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>

              <Col sm={12} md={3}>
                <ErrorWrapper error={errors.issueDate}>
                  <Label>Data de Emissão</Label>
                  <Controller
                    control={control}
                    name='issueDate'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Date value={value} id='employeeForm_issueDate' onChange={onChange} getInputRef={ref} />
                    )}
                  />
                  {errors.issueDate && <ErrorMessage>{errors.issueDate.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>

              <Col sm={12} md={3}>
                <ErrorWrapper error={errors.issuingBody}>
                  <Label>Orgão Emissor</Label>
                  <Input
                    placeholder='Orgão Emissor'
                    maxLength='10'
                    id='employeeForm_issuingBody'
                    {...register('issuingBody')}
                  />
                </ErrorWrapper>
                {errors.issuingBody && <ErrorMessage>{errors.issuingBody.message}</ErrorMessage>}
              </Col>

              <Col sm={12} md={3}>
                <span>
                  <Label>Estado</Label>
                  <Controller
                    control={control}
                    name='state'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        inputRef={ref}
                        placeholder='Selecione o Estado RG'
                        id='employeeForm_state'
                        value={value}
                        onChange={onChange}
                        isDisabled={readOnly}
                        options={states}
                      />
                    )}
                  />
                </span>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={4}>
                <ErrorWrapper error={errors.gender}>
                  <Label>Gênero</Label>
                  <Controller
                    control={control}
                    name='gender'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        inputRef={ref}
                        placeholder={'Selecione'}
                        value={value}
                        onChange={onChange}
                        id='employeeForm_gender'
                        isDisabled={readOnly}
                        options={genderOptions}
                      />
                    )}
                  />
                  {errors.gender && (
                    <ErrorMessage>{errors.gender.value?.message || errors.gender.label?.messag}</ErrorMessage>
                  )}
                </ErrorWrapper>
              </Col>
              <Col sm={12} md={4}>
                <ErrorWrapper error={errors.motherName}>
                  <Label>Nome da Mãe</Label>
                  <Input
                    placeholder='Nome da Mãe'
                    maxLength='120'
                    id='employeeForm_motherName'
                    {...register('motherName')}
                  />
                  {errors.motherName && <ErrorMessage>{errors.motherName.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>
          </Container>
        </FieldSet>
      </form>
      <Row justify='end' gutterWidth={16} style={{ marginTop: '8vh' }}>
        <Col sm='content'>
          {mode === FORM_MODE.VIEW ? (
            <Button variant='outlined' onClick={returnToList} id='fechar'>
              Fechar
            </Button>
          ) : (
            <Button variant='outlined' onClick={cancel}>
              Cancelar
            </Button>
          )}
        </Col>
        <Col sm='content'>
          {mode === FORM_MODE.VIEW ? (
            <Button type='button' onClick={nextTab} id='proximo'>
              Próximo
            </Button>
          ) : (
            <Button type='submit' form='personalData'>
              Avançar
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default PersonalData
