import { styled } from '@mui/material'

export const Container = styled('div')(({ theme }) => ({
  width: 180,
  height: 55,
  border: `1px solid ${theme.palette.common.light}`,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 16,
  div: {
    display: 'flex',
    gap: 12,
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: 600,
  },
}))
