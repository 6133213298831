import moment from 'moment'
import { AutoTable } from '@stationkim/front-ui'
import { AutoTableProps } from 'presentation/components/molecules'
import { ORDER_METHOD, ORDER_STATUS, ORDER_TYPES } from 'utils/constants'
import { pushNotification } from 'store/modules/notification/actions'
import errorHandler from 'utils/errorHandler'
import api from '@/infra/api'
import { useDispatch } from 'react-redux'
import blobDownload from 'utils/blobDownload'
import { getRechargeReceipt, getRechargeSignaturesReport } from 'services/reportsServices'
import blobToJson from 'utils/blobToJson'
import { formatMoney } from 'utils/functionUtils'
import { Box, CircularProgress, Icon, MenuItem, MenuUncontrolled, Tooltip } from 'presentation/components/atoms'
import { faFileLines } from '@fortawesome/free-regular-svg-icons'
import { faBarcode, faClipboardList, faEllipsis, faWallet } from '@fortawesome/free-solid-svg-icons'
import { useList } from 'main/hooks'
import { useSetAtom } from 'jotai'
import { orderIdAtom } from 'main/store'
import { IOrderBasicInformation } from 'main/interfaces/iOrderBasicInformation'
import { useNavigate } from 'react-router-dom'
import { ORDER_PAYMENT_TYPE } from '@/main/utils/constants'
import { faPix } from '@fortawesome/free-brands-svg-icons'

interface RechargesTableProps {
  onRowClick?: any
  isLoading: any
  changeOrder: ({ fieldName, isDesc }: { fieldName: string; isDesc: boolean }) => void
  cancelOrder: ({ order }: { order: any }) => void
  rows: any
}

export const RechargesTable = ({ isLoading, rows, changeOrder, cancelOrder }: RechargesTableProps) => {
  const dispatch = useDispatch()
  const setOrderId = useSetAtom(orderIdAtom)
  const navigate = useNavigate()
  const slipList = useList<string>()
  const orderReceiptList = useList<string>()
  const requestSignaturesListList = useList<string>()

  const openBill = async (orderId: string) => {
    slipList.add({ value: orderId })
    try {
      const response = await api.get(`rh-bff/cards/orders/${orderId}/slip`)
      window.open(response.data)
    } catch (error) {
      dispatch(pushNotification(errorHandler(error.response)))
    }
    slipList.remove({ value: orderId })
  }

  const requestOrderReceipt = async (order: any) => {
    orderReceiptList.add({ value: order.id })
    const { response, error } = await getRechargeReceipt(order.code)
    if (response) {
      const blob = new Blob([response.data], { type: 'text/plain' })
      const filename = `Recibo de pedido de recarga #${order.code}.pdf`
      blobDownload(blob, filename)
    } else
      dispatch(
        pushNotification(
          errorHandler({
            ...error.response,
            data: await blobToJson(error.response.data),
          }),
        ),
      )
    orderReceiptList.remove({ value: order.id })
  }

  const requestSignaturesList = async (order: any) => {
    requestSignaturesListList.add({ value: order.id })
    const { response, error } = await getRechargeSignaturesReport(order.id)
    if (response) {
      const blob = new Blob([response.data], { type: 'text/plain' })
      const filename = `Lista de assinaturas pedido de recarga #${order.code}.pdf`
      blobDownload(blob, filename)
    } else
      dispatch(
        pushNotification(
          errorHandler({
            ...error.response,
            data: await blobToJson(error.response.data),
          }),
        ),
      )
    requestSignaturesListList.remove({ value: order.id })
  }

  const viewOrder = (order: any) => {
    if (order.status === ORDER_STATUS.DRAFT) {
      if (order.orderType === ORDER_TYPES.BROKER) {
        setOrderId(order.id)
        navigate('/recargas/pedido_recarga_broker/dados', { state: { orderId: order.id } })
      } else {
        navigate(
          order.method === ORDER_METHOD.IMPORT
            ? `/recargas/novo_pedido_importacao_valores#orderId="${order.id}"`
            : `/recargas/novo_pedido_manual/#orderId="${order.id}"`,
        )
      }
    } else navigate(`/recargas/acompanhamento/#orderId="${order.id}"`)
  }

  const renderDocumentIcons = ({ row }: { row: any }) => {
    const renderIcon = ({ icon, title, onClick }: { icon: any; title: string; onClick: () => void }) => (
      <Tooltip
        title={title}
        onClick={(e: any) => {
          e.stopPropagation()
          onClick && onClick()
        }}
      >
        <Icon icon={icon} />
      </Tooltip>
    )
    const loading = <CircularProgress size='16px' />

    const docIcons = () => {
      switch (ORDER_PAYMENT_TYPE[row?.paymentType]) {
        case 'Boleto': {
          return faBarcode
        }
        case 'Pix': {
          return faPix
        }
        case 'Carteira digital': {
          return faWallet
        }
      }
    }

    return (
      <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
        {row.status === ORDER_STATUS.FINISHED &&
          (requestSignaturesListList.list.includes(row.id)
            ? loading
            : renderIcon({
                icon: faClipboardList,
                title: 'Lista de assinaturas',
                onClick: () => requestSignaturesList(row),
              }))}
        {row.status === ORDER_STATUS.FINISHED &&
          (orderReceiptList.list.includes(row.id)
            ? loading
            : renderIcon({ icon: faFileLines, title: 'Recibo', onClick: () => requestOrderReceipt(row) }))}
        {row.status === ORDER_STATUS.WAITING_PAYMENT &&
          (slipList.list.includes(row.id)
            ? loading
            : renderIcon({
                icon: docIcons(),
                title: ORDER_PAYMENT_TYPE[row?.paymentType],
                onClick: () =>
                  ORDER_PAYMENT_TYPE[row?.paymentType] === 'Boleto'
                    ? openBill(row.id)
                    : navigate(`/recargas/acompanhamento/#orderId="${row.id}"`),
              }))}
      </Box>
    )
  }

  const columnsConfiguration: AutoTableProps<IOrderBasicInformation>['columns'] = [
    {
      columnDisplayName: 'Nr. Pedido',
      columnValue: ({ row }) => String(row.code),
      onOrder: ({ order }) => changeOrder({ fieldName: 'code', isDesc: order }),
    },
    {
      columnDisplayName: 'Data do Pedido',
      columnValue: ({ row }) => row.orderDate,
      customRender: ({ row }) => moment(row.orderDate).format('DD/MM/YYYY'),
      onOrder: ({ order }) => changeOrder({ fieldName: 'orderDate', isDesc: order }),
    },
    {
      columnDisplayName: 'Operadora',
      columnValue: ({ row }) => row.operatorName,
      onOrder: ({ order }) => changeOrder({ fieldName: 'operatorName', isDesc: order }),
    },
    {
      columnDisplayName: 'Valor',
      columnValue: ({ row }) => `R$ ${formatMoney(row.totalValue)}`,
      onOrder: ({ order }) => changeOrder({ fieldName: 'totalVale', isDesc: order }),
    },
    {
      columnDisplayName: 'Status',
      columnValue: ({ row }) => ORDER_STATUS[row.status],
    },
    {
      columnDisplayName: 'Tipo de pedido',
      columnValue: ({ row }) => ORDER_TYPES[row.orderType],
      onOrder: ({ order }) => changeOrder({ fieldName: 'orderType', isDesc: order }),
    },
    {
      columnDisplayName: 'Documentos',
      columnValue: ({ row }) => row.id,
      align: 'center',
      customRender: ({ row }) => renderDocumentIcons({ row }),
      preventLink: true,
    },
    {
      columnDisplayName: 'Ações',
      columnValue: ({ row }) => '',
      align: 'center',
      preventLink: true,
      customRender: ({ row }) => {
        return (
          [ORDER_STATUS.WAITING_PAYMENT, ORDER_STATUS.DRAFT].includes(row.status) && (
            <Box
              onClick={(e: any) => {
                e.stopPropagation()
              }}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <MenuUncontrolled
                renderItems={() => (
                  <MenuItem
                    onClick={(e: any) => {
                      e.stopPropagation()
                      cancelOrder({
                        order: row,
                      })
                    }}
                  >
                    Cancelar pedido
                  </MenuItem>
                )}
              >
                <Icon icon={faEllipsis} />
              </MenuUncontrolled>
            </Box>
          )
        )
      },
    },
  ]

  return (
    <AutoTable
      onRowClick={({ row }) => viewOrder(row)}
      isLoading={isLoading}
      rows={rows}
      columns={columnsConfiguration}
      link={({ row }) => {
        return `/recargas/acompanhamento/#orderId="${row.id}"`
      }}
    />
  )
}
