export interface IFeatureFlags {
  [key: string]: {
    cart: boolean
    methodPayments: boolean
    methodPaymentCart: boolean
    ticketingBalance: boolean
  }
}
export const featureFlags: IFeatureFlags = {
  development: {
    cart: true,
    methodPayments: true,
    methodPaymentCart: true,
    ticketingBalance: true,
  },
  stage: {
    cart: true,
    methodPayments: true,
    methodPaymentCart: false,
    ticketingBalance: true,
  },
  production: {
    cart: true,
    methodPayments: true,
    methodPaymentCart: false,
    ticketingBalance: true,
  },
}
