import { FieldTableVirtualized, Box, Button, Grid, Icon, Search, Skeleton, Typography } from '@stationkim/front-ui'
import { useContext, useEffect, useState } from 'react'
import { useImportResults } from './hooks/useImportResults'
import { useTheme } from '@mui/material'
import { faArrowUpRightFromSquare, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { CooldownButton } from './components/cooldownButton'
import { useLocation, useNavigate } from 'react-router-dom'
import { BrokerHOC } from '../brokerHOC'

const ResultCounter = ({
  isLoading,
  type = 'employee',
  value,
}: {
  isLoading: boolean
  type?: 'employee' | 'error'
  value: string | number
}) => {
  const theme = useTheme()
  const typeText: { [Key in 'employee' | 'error']: string } = {
    employee: 'Colaboradores no pedido',
    error: 'Erros',
  }
  return (
    <Typography
      variant='h5'
      sx={{
        display: 'flex',
        gap: '8px',
        fontWeight: 400,
        '& span': {
          color: type === 'error' && Number(value) > 0 ? theme.palette.error.main : 'initial',
          fontSize: '1.5rem',
          fontWeight: 600,
        },
      }}
    >
      {typeText[type]}: {isLoading ? <Skeleton variant='rounded' width='32px' /> : <span>{value}</span>}
    </Typography>
  )
}

export const BrokerImportResults = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const importId = location?.state?.importId
  const { results, isLoading, createOrder, editRow, getReport, getReportLoading, deleteRow } = useImportResults({
    orderId: importId,
  })
  const hasError = results.errorsCount > 0
  const isAllErrors = results.errorsCount === results.rows.length
  const [search, setSearch] = useState('')
  const searchOnList = () => {
    if (search === '') return results.rows
    return results.rows.filter((row) => {
      return Object.keys(row).some((column) => {
        if (typeof row[column] === 'string') {
          return row[column].toLowerCase().includes(search.toLowerCase())
        }
        return false
      })
    })
  }

  useEffect(() => {
    if (!importId) navigate('/recargas')
  }, [navigate, importId])

  const filteredList = searchOnList()
  const visibleRows = isLoading || results.rows?.length >= 10 ? 11 : results.rows?.length + 1
  return (
    <BrokerHOC>
      <Grid container spacing='16px' sx={{ padding: '32px' }} alignItems='center'>
        {!isLoading && (
          <Grid item xs={12} sx={{ marginBottom: '12px' }}>
            <Box
              sx={{
                color: theme.palette.common.white,
                backgroundColor: hasError ? theme.palette.error.dark : theme.palette.success.dark,
                padding: '6px 16px',
                borderRadius: '4px',
              }}
            >
              <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                <Icon icon={hasError ? faExclamationCircle : faCheckCircle} />
                <Typography variant='subtitle2'>
                  {hasError ? 'A importação não foi completa' : 'Importação concluída!'}
                </Typography>
              </Box>
              <Box sx={{ paddingLeft: '26px' }}>
                <Typography variant='label3'>
                  {hasError
                    ? `Foram encontrados erros em alguns dados informados no arquivo importado "${results.fileName}".` +
                      ' Edite os itens, ou baixe o relatório de erros e reenvie o arquivo com os erros corrigidos em um novo pedido.' +
                      ' Ao continuar, o pedido será feito de forma parcial, apenas com os colaboradores que foram importados com sucesso.'
                    : 'Verifique as informações e continue para a próxima etapa.'}
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing='24px'>
            <Grid item xs={12} sm={'auto'} md='auto'>
              <ResultCounter isLoading={isLoading} value={results.employeesCount} />
            </Grid>
            <Grid item xs={12} sm={'auto'} md='auto'>
              <ResultCounter isLoading={isLoading} type='error' value={results.errorsCount} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={5} xl={4} sx={{ marginTop: { xs: 0, sm: '8px' } }}>
          <Search placeholder='Buscar na lista abaixo' onSearch={(value) => setSearch(value)} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md='auto'
          sx={{
            marginLeft: 'auto',
            marginTop: { xs: 0, sm: '8px' },
          }}
        >
          {hasError ? (
            <Button
              variant='outlined'
              fullWidth
              endIcon={<Icon icon={faArrowUpRightFromSquare} />}
              sx={{
                height: {
                  xs: 'fit-content',
                  sm: '48px',
                },
              }}
              disabled={getReportLoading}
              onClick={() => getReport()}
            >
              Baixar relatório de erros
            </Button>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <FieldTableVirtualized
            visibleRows={visibleRows}
            rows={filteredList}
            columns={results.columns}
            isLoading={isLoading}
            onRowDelete={({ row }) => {
              deleteRow(row.id)
            }}
            onRowEdit={({ row }) => editRow(row)}
            frozenActionColumn='right'
          />
        </Grid>
        <Grid item xs={12} sm={6} md='auto' sx={{ marginLeft: 'auto' }}>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => navigate('/recargas/pedido_recarga_broker', { state: { isDealer: false } })}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md='auto'>
          <CooldownButton
            disabled={isAllErrors}
            skipCount={results.errorsCount === 0}
            isLoading={isLoading}
            onClick={() => createOrder()}
          />
        </Grid>
      </Grid>
    </BrokerHOC>
  )
}
