import { PrimitiveAtom, useAtom } from 'jotai'

interface IUseFilter {
  filterAtom: PrimitiveAtom<object>
  initialValue?: object
}
export const useFilter = ({ filterAtom, initialValue = {} }: IUseFilter) => {
  const [filter, setFilter] = useAtom(filterAtom)

  const resetFilter = () => setFilter({ ...initialValue })
  const updateFilter = (filterValues: object) =>
    setFilter(() => {
      if (filter) return { ...filter, ...filterValues }
      return { ...initialValue, ...filterValues }
    })
  const overrideFilter = (filterValues: object) => setFilter(filterValues)

  return {
    filter: (filter as any) || initialValue,
    setFilter,
    resetFilter,
    updateFilter,
    overrideFilter,
  }
}
