import { useState } from 'react'

import api from '@/infra/api'

import { useNotification } from '@/main/hooks/useNotification'
import { useNavigate } from 'react-router-dom'

export const useOrder = ({ orderIdToEdit }) => {
  const [selecteds, setSelecteds] = useState([])
  const [unselecteds, setUnselecteds] = useState([])
  const [initialState, setInitialState] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { errorNotification } = useNotification()

  const notIn = (a, b) => {
    return a.filter((item) => !b.find((employee) => employee.id === item.id))
  }

  const prepareEmployeesIdsToSendToApi = (employees) => {
    return employees.map((employee) => employee.cardId)
  }

  const nextPage = (orderId) => {
    navigate('/recargas/novo_pedido_manual_valores', {
      state: { orderId, bypass: true },
    })
  }

  const createOrder = async () => {
    setLoading(true)
    try {
      const response = await api.post('rh-bff/recharge/new/Manual', prepareEmployeesIdsToSendToApi(selecteds))
      nextPage(response.data)
    } catch (error) {
      errorNotification(error.response)
    }
    setLoading(false)
  }

  const changeEmployeesInOrder = async () => {
    const added = notIn(selecteds, initialState)
    const removed = initialState.filter((item) => unselecteds.find((unselected) => unselected?.id === item?.id))

    setLoading(true)
    try {
      added.length > 0 &&
        (await api.patch(`/orders-service/Recharges/${orderIdToEdit}/add`, prepareEmployeesIdsToSendToApi(added)))
      removed.length > 0 &&
        (await api.patch(`/orders-service/Recharges/${orderIdToEdit}/remove`, prepareEmployeesIdsToSendToApi(removed)))
      nextPage(orderIdToEdit)
    } catch (error) {
      errorNotification(error.response)
    }
    setLoading(false)
  }

  const cancelOrder = async () => {
    setLoading(true)
    try {
      await api.patch(`/orders-service/${orderIdToEdit}/cancel`)
      navigate('/recargas', {
        state: { bypass: true },
      })
    } catch (error) {
      errorNotification(error.response)
    }
    setLoading(false)
  }

  return {
    selecteds,
    setSelecteds,
    setInitialState,
    loading,
    setLoading,
    createOrder,
    cancelOrder,
    changeEmployeesInOrder,
    unselecteds,
    setUnselecteds,
  }
}
