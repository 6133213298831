import { Box, Tooltip, Typography } from 'presentation/components/atoms'
import { formatMoney } from 'main/utils/functions'
import { ORDER_TYPES } from 'main/utils/constants'
import { useMediaQuery, useTheme } from '@mui/material'
import { useContext, useRef } from 'react'
import { OrderTrackingContext } from 'presentation/pages/orderTracking/orderTracking'
import { hasWidthOverflow } from 'main/utils/functions'

export const OrderTitle = ({ isCanceled }: { isCanceled?: boolean }) => {
  const theme = useTheme()
  const operatorNameRef = useRef()
  const isLowerThanMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerThanSm = useMediaQuery(theme.breakpoints.down('sm'))

  const { orderCode, orderType, orderOperatorName, orderSummary, isRecharge } = useContext(OrderTrackingContext)

  const isFirstCopy = ORDER_TYPES.FIRST_COPY === orderType
  const typeName = isRecharge ? 'Recarga' : `Cartão - ${isFirstCopy ? 1 : 2}ª via`

  const isOperatorNameOverflowing = hasWidthOverflow({ element: operatorNameRef.current })

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        color: isCanceled && theme.palette.grey[500],
        gap: '16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '32px',
          marginRight: '32px',
        }}
      >
        <Typography variant='h6' sx={{ whiteSpace: 'nowrap', order: 1 }}>
          {typeName}
        </Typography>
        <Typography variant='h6' sx={{ order: 1 }}>
          #{orderCode}
        </Typography>
      </Box>

      {orderOperatorName && (
        <Tooltip title={isOperatorNameOverflowing ? orderOperatorName : null}>
          <Typography
            ref={operatorNameRef}
            variant='h6'
            sx={{
              fontWeight: 400,
              whiteSpace: 'nowrap',
              order: isLowerThanMd ? 4 : 3,
              flex: isLowerThanMd ? '1 0 100%' : 1,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            Operadora: {orderOperatorName}
          </Typography>
        </Tooltip>
      )}
      <Typography
        variant='h6'
        sx={{
          marginLeft: 'auto',
          order: isLowerThanMd ? 3 : 4,
          flex: isLowerThanSm ? '1 0 100%' : null,
        }}
      >
        <small style={{ fontSize: '0.75rem' }}>R$</small>
        <Box
          sx={{
            display: 'inline-block',
            marginLeft: '4px',
            position: 'relative',
            '&:after': {
              content: isCanceled && '" "',
              position: 'absolute',
              height: '1px',
              backgroundColor: theme.palette.grey[500],
              top: '50%',
              left: '-2px',
              right: '0px',
            },
          }}
        >
          {formatMoney(orderSummary.orderTotal)}
        </Box>
      </Typography>
    </Box>
  )
}
