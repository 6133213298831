import { useContext, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { Typography } from '@stationkim/front-ui'
import { PaymentConfirmationContext } from '@/presentation/pages/paymentConfirmation/paymentConfirmation'
import { faPix } from '@fortawesome/free-brands-svg-icons'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@mui/material'
import { Option } from './option/option'
import { OptionsContainer } from './styles'

export enum EPaymentMethod {
  Slip,
  Pix,
  InstallmentSlip,
}

export const Method = () => {
  const theme = useTheme()
  const { form } = useContext(PaymentConfirmationContext)

  const methods = useMemo(
    () => [
      {
        value: EPaymentMethod.Slip,
        label: 'Boleto',
        icon: faBarcode,
        iconColor: { checked: theme.palette.primary.main },
      },
      {
        value: EPaymentMethod.Pix,
        label: 'Pix',
        icon: faPix,
        iconColor: { checked: '#00AD9F' },
        isNew: true,
      },
    ],
    [theme],
  )

  return (
    <>
      <Typography variant='subtitle2' sx={{ marginBottom: '16px' }}>
        Método de pagamento
      </Typography>
      <Controller
        control={form.control}
        name='paymentMethod'
        render={({ field: { onChange, value } }) => {
          return (
            <OptionsContainer>
              {methods.map((method, index) => (
                <Option
                  key={'paymentMethod_' + index}
                  icon={method.icon}
                  value={method.value}
                  onClick={onChange}
                  checked={value === method.value}
                  iconColor={method.iconColor}
                  isNew={method.isNew}
                >
                  {method.label}
                </Option>
              ))}
            </OptionsContainer>
          )
        }}
      />
    </>
  )
}
