import { useState } from 'react'
import { Divider, Grid, Paper } from 'presentation/components/atoms'
import { Content } from 'presentation/components'
import { Payment, CartSkeleton, Details, NoItems } from 'presentation/components/orders'
import { PageHeader } from 'presentation/components/molecules'
import { useLocation } from 'react-router-dom'
import { getResaleSiteFromUrl } from '@/utils/functionUtils'
import { useRequestInfos } from './hooks/useRequestInfos/useRequestInfos'
import { useOrderOldIntegration } from './hooks/useOrderOldIntegration'

export const OldPaymentConfirmation = () => {
  const location = useLocation()
  const orderId = location.state?.orderId
  const isDealer = !location?.state?.isBroker

  const { isLoading: isLoadingInfos, data, details } = useRequestInfos({ orderId })
  const { isLoading: isLoadingActions, confirmOrder, cancelOrder } = useOrderOldIntegration({ data })
  const isLoading = isLoadingInfos || isLoadingActions

  const [method, setMethod] = useState<'PIX' | 'SLIP' | 'TERM_BILL'>('SLIP')
  const [rate, setRate] = useState({
    interestRateDays: 0,
    interestRate: 0,
    nextDueDate: '',
    rateValue: 0,
    totalValueWithInterestRate: 0,
  })

  const [balanceToBeUsed, setBalanceToBeUsed] = useState(0)
  const [useBalanceProjection, setUseBalanceProjection] = useState(getResaleSiteFromUrl() === 'motiva' ? true : false)

  const isEmpty = location.state?.quantity <= 0
  return (
    <Content>
      <Paper sx={{ padding: '32px' }}>
        <Grid container justifyContent='space-between' spacing={2}>
          <Grid item xs={12}>
            <PageHeader pageTitle='Comprar agora' noDivider padding='0' />
          </Grid>
        </Grid>
        <Divider sx={{ margin: '32px 0' }} />
        {isLoading ? (
          <CartSkeleton />
        ) : (
          <Grid container columnSpacing='32px' rowSpacing='32px'>
            <Grid item xs={12} md={7}>
              {isEmpty ? (
                <NoItems />
              ) : (
                <Details
                  total={data.totalBeforeBalanceUsage}
                  title={location.state?.title}
                  items={details}
                  operatorCardName={location.state?.operatorCardName}
                  cancelOrder={() => cancelOrder({ orderId })}
                />
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <Payment
                isDealer={isDealer}
                isEmpty={isEmpty}
                order={data as any}
                balanceToBeUsed={balanceToBeUsed}
                setBalanceToBeUsed={setBalanceToBeUsed}
                setUseBalanceProjection={setUseBalanceProjection}
                shouldUseProjection={useBalanceProjection}
                confirmOrder={() =>
                  confirmOrder({
                    orderId,
                    method,
                    useBalanceProjection,
                    balanceUsage: balanceToBeUsed,
                    //data,
                    rate,
                  })
                }
                setMethod={setMethod}
                method={method}
                setRate={setRate}
                rate={rate}
              />
            </Grid>
          </Grid>
        )}
      </Paper>
    </Content>
  )
}
