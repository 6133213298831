import { TableCell, TableRow } from 'presentation/components/atoms'

import { TableHead as Head } from './styles'

export const TableHead = () => {
  return (
    <Head>
      <TableRow>
        <TableCell>Colaboradores</TableCell>
        <TableCell>Benefícios associados</TableCell>
        <TableCell>Qtd Dias</TableCell>
        <TableCell>Valor Recarga</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </Head>
  )
}
