import paginatorMethods from '@/utils/paginatorMethods'

import { TableCell, TableRow, TableHead as Head } from 'presentation/components/atoms'
import { Wrapper } from '../../../../styles'

import { OrderIcon } from 'presentation/components'

export const TableHead = ({ setPaginator, paginator }) => {
  return (
    <Head>
      <TableRow>
        <TableCell>Nome Cartão</TableCell>
        <TableCell>
          <Wrapper>
            Nº Cartão
            <OrderIcon
              clickUp={() => setPaginator(paginatorMethods.setOrder(paginator, 'number'))}
              clickDown={() => setPaginator(paginatorMethods.setOrder(paginator, 'number', false))}
            />
          </Wrapper>
        </TableCell>
        <TableCell>
          <Wrapper>
            Colaborador
            <OrderIcon
              clickUp={() => setPaginator(paginatorMethods.setOrder(paginator, 'employeeName'))}
              clickDown={() => setPaginator(paginatorMethods.setOrder(paginator, 'employeeName', false))}
            />
          </Wrapper>
        </TableCell>
        <TableCell>Matrícula Operadora</TableCell>
        <TableCell>Benefício</TableCell>
        <TableCell>Valor Diário</TableCell>
        <TableCell>Saldo</TableCell>
        <TableCell>Status</TableCell>
        <TableCell style={{ textAlign: 'center' }}>Ações</TableCell>
      </TableRow>
    </Head>
  )
}
