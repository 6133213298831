import _ from 'lodash'
import { Background, Content } from '@/presentation/components'
import { Image } from './styles'
import Img from '@/presentation/assets/images/home.png'
import { useSelector } from 'react-redux'
import { PageHeader, Panel } from '@/presentation/components/molecules'
import { Box } from '@/presentation/components/atoms'
import { useAtom, useAtomValue } from 'jotai'
import { AccountPersistentStore, GlobalThemePersistentStore } from '@/main/store'
import { useEffect } from 'react'
import { defaultTheme } from '@/config/themes/defaultTheme'
import { Carrousel } from './carrousel/carrousel'

const Home = () => {
  const account = useAtomValue(AccountPersistentStore)
  const [theme, setTheme] = useAtom(GlobalThemePersistentStore)
  useEffect(() => {
    if (!account.resale?.theme) return
    if (Object.keys(JSON.parse(account.resale.theme)).length === 0) {
      setTheme(defaultTheme)
      return
    }
    setTheme(_.merge(theme, JSON.parse(account.resale.theme)))
  }, [])
  // todo remove this as soon as possible
  const isTargetResale =
    useSelector((state: any) => state?.auth?.user?.resaleId) === '79638307-2d03-4780-8159-971b6a4c22a8'
  const isMessageValid = new Date() < new Date('2024/02/18')
  return (
    <>
      {isMessageValid && isTargetResale ? (
        <Panel sx={{ minHeight: '50vh' }}>
          <PageHeader pageTitle='Aviso' />
          <Box sx={{ padding: '32px' }}>
            <Carrousel />
          </Box>
        </Panel>
      ) : (
        <Content>
          <Image src={Img} />
        </Content>
      )}
    </>
  )
}

export default Home
