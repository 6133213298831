import { ReactElement, useState } from 'react'
import { IconContainer, StyledAccordion, SummaryContainer } from './styles'
import { AccordionDetails, AccordionSummary, Box, Icon, Typography } from 'presentation/components/atoms'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@mui/material'

export interface SummaryProps {
  title?: string | ReactElement
  subtitle?: string
  detailText?: string
  lessDetailText?: string
  icon?: ReactElement
  children?: any
  noMargin?: boolean
  isInactive?: boolean
}

export const Summary = ({
  detailText = 'Ver mais detalhes',
  lessDetailText = 'Ver menos detalhes',
  title,
  subtitle,
  icon,
  children,
  noMargin = false,
  isInactive,
}: SummaryProps) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <SummaryContainer sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        {icon && (
          <IconContainer sx={{ '& svg>path': { fill: isInactive && theme.palette.grey[500] } }}>{icon}</IconContainer>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            color: isInactive ? theme.palette.grey[500] : theme.palette.grey[700],
            maxWidth: icon ? 'calc(100% - 66px)' : '100%',
          }}
        >
          {typeof title !== 'string' ? title : <Typography variant='h4'>{title}</Typography>}
          <Typography
            variant='label2'
            sx={{
              fontWeight: 400,
              color: isInactive ? theme.palette.grey[500] : theme.palette.grey[700],
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
      {children && (
        <StyledAccordion
          expanded={isOpen}
          onChange={() => {
            setIsOpen((state) => !state)
          }}
          elevation={0}
          disableGutters
          square
          noMargin={noMargin}
        >
          <AccordionSummary expandIcon={<Icon icon={faChevronDown} />}>
            <Typography>{isOpen ? lessDetailText : detailText}</Typography>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </StyledAccordion>
      )}
    </SummaryContainer>
  )
}
