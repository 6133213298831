import { Box, Icon } from '@/presentation/components/atoms'
import Img01 from './tempImages/motiva-bloco-carnaval-01.png'
import Img02 from './tempImages/motiva-bloco-carnaval-02.png'
import Img03 from './tempImages/motiva-bloco-carnaval-03.png'
import Img04 from './tempImages/motiva-bloco-carnaval-04.png'
import Img05 from './tempImages/motiva-bloco-carnaval-05.png'
import { useState } from 'react'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { IconButton, useMediaQuery, useTheme } from '@mui/material'

const Arrow = ({ icon, ...props }: any) => {
  const theme = useTheme()
  const isLowerThanMd = useMediaQuery('@media (max-width: 850px)')
  if (isLowerThanMd) return null
  return (
    <Box
      {...props}
      sx={{
        cursor: 'pointer',
        transition: 'all .15s ease-in-out',
        '&:active': {
          transform: 'scale(.8)',
        },
        '& svg': {
          height: '32px',
          width: '32px',
          transition: 'all .15s ease-in-out',
          color: theme.palette.grey[700] + 'D9',
        },
        '& svg:hover': {
          transform: 'scale(1.2)',
        },
      }}
    >
      <IconButton>
        <Icon icon={icon} />
      </IconButton>
    </Box>
  )
}

const ImageDot = ({ active, ...props }: any) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        height: '16px',
        width: '16px',
        borderRadius: '50%',
        backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[100],
        cursor: 'pointer',
        transition: 'all .5s ease-in-out',
      }}
      {...props}
    />
  )
}

export const Carrousel = () => {
  const images = [Img01, Img02, Img03, Img04, Img05]
  const [actualImage, setActualImage] = useState(0)

  const handleNext = () => {
    setActualImage((actual) => {
      const target = actual + 1
      if (target >= images.length) return 0
      return target
    })
  }

  const handlePrevious = () => {
    setActualImage((actual) => {
      const target = actual - 1
      if (target < 0) return images.length - 1
      return target
    })
  }

  const handleGoTo = ({ index }: { index: number }) => {
    setActualImage(index)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Arrow icon={faChevronLeft} onClick={handlePrevious} />
        <Box
          sx={{
            width: '666px',
            display: 'flex',
            overflow: 'hidden',
            padding: '16px',
          }}
        >
          <Box
            sx={{
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.10)',
              transition: 'all 1s linear',
            }}
          >
            <img
              src={images[actualImage]}
              alt='imagem informativa'
              style={{
                maxWidth: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Box>
        <Arrow icon={faChevronRight} onClick={handleNext} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          justifyContent: 'center',
          width: 'fit-content',
        }}
      >
        {images.map((_image, index) => {
          return (
            <ImageDot key={index + '_image'} active={actualImage === index} onClick={() => handleGoTo({ index })} />
          )
        })}
      </Box>
    </Box>
  )
}
