import React from 'react'
import { Breadcrumb } from '..'
import { PageHeaderContainer, PageName } from './styles'

const PageHeader = ({ children, borderless, location }) => {
  return (
    <PageHeaderContainer borderless={borderless}>
      <PageName>{children}</PageName>
      <Breadcrumb />
    </PageHeaderContainer>
  )
}

export default PageHeader
