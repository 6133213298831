import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAtomValue } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { IntegrationSwapper } from '@/presentation/components'
import { OrderTracking as OrderTrackingPage } from './orderTracking'
import { useNotifications, usePagination } from '@/main/hooks'
import { useOrderDetail } from './oldHooks/useOrderDetail'
import { ORDER_TYPES } from '@/main/utils/constants'
import { errorHandler } from '@/main/utils/functions/errorHandler'
import { useOrderTracking } from './hooks/useOrderTracking'
import { useOrderItems } from './oldHooks/useOrderItems'
import { useFilter } from '@stationkim/front-ui'
import { usePix } from '@/main/hooks/usePix'
import { EStatusCompra } from '@/main/enums'

export const isDealerOrderHash = atomWithHash('isDealerOrder', true)
const filterAtom = atomWithHash<object>('orderItemsFilter', {})
const paginationAtom = atomWithHash<object>('orderItemsPagination', null)

const OldIntegration = () => {
  const navigate = useNavigate()
  const notify = useNotifications()

  const { filter, updateFilter } = useFilter({ filterAtom })
  const { pagination, goToPage, setPageSize } = usePagination({
    paginationAtom,
    initialValue: {
      pageSize: 200,
    },
  })

  const { items: orderItems } = useOrderItems({ filter, pagination })

  const { order, isLoading: isLoadingOrder } = useOrderDetail({
    config: {
      onError: (e) => {
        navigate('/recargas')
        notify.push(errorHandler(e?.response?.data?.errors))
      },
    },
  })

  const shouldPoolForPix =
    order?.usedPaymentsTypes?.includes('PIX') && order?.status === EStatusCompra.AguardandoPagamento
  const pixInfo = shouldPoolForPix
    ? { orderId: order?.orderId, orderType: order?.orderType ? 'cards' : 'recharge' }
    : { orderId: null, orderType: null }

  const { data } = usePix(pixInfo as any)

  if (order) {
    if (data?.pix) {
      order.pix = {
        qrCode: data.pix?.qrCode,
        orderDate: order.orderDate,
        value: order.orderSummary.totalPaid,
      }
    } else {
      order.pix = {
        qrCode: '',
        orderDate: '',
        value: 0,
      }
    }
  }

  const isLoading = isLoadingOrder
  const isRecharge = order?.orderType >= ORDER_TYPES.RECHARGE

  const handleReturn = useCallback(() => {
    const targetPath = isRecharge
      ? { pathname: '/recargas' }
      : {
          pathname: '/cartoes',
          state: { order: true },
        }
    navigate(targetPath)
  }, [isRecharge, navigate])

  return (
    <OrderTrackingPage
      handleReturn={handleReturn}
      isLoading={isLoading}
      isRecharge={isRecharge}
      order={order}
      items={{
        filter,
        goToPage,
        isLoading,
        orderItems,
        setPageSize,
        updateFilter,
      }}
    />
  )
}

const NewIntegration = () => {
  const navigate = useNavigate()
  const { filter, updateFilter } = useFilter({ filterAtom })
  const { pagination, goToPage, setPageSize } = usePagination({
    paginationAtom,
    initialValue: {
      page: 1,
      pageSize: 200,
    },
    outputMap: {
      page: 'pagina',
      pageSize: 'qtdLinhas',
    },
  })

  const { order, isLoading, isItemsLoading } = useOrderTracking({ filter, pagination })

  const isRecharge = true

  const handleReturn = () => navigate('/recargas')
  return (
    <OrderTrackingPage
      handleReturn={handleReturn}
      isLoading={isLoading}
      isRecharge={isRecharge}
      order={order}
      items={{
        filter,
        goToPage,
        setPageSize,
        updateFilter: (data: any) => updateFilter({ pesquisa: data.filterValue }),
        isLoading: isItemsLoading,
        orderItems: order?.orderItems,
      }}
    />
  )
}

export const OrderTracking = () => {
  const isDealer = useAtomValue(isDealerOrderHash)

  return (
    <IntegrationSwapper
      isDealer={isDealer}
      components={{
        ifIsMotiva: <OldIntegration />,
        ifIsNotMotiva: <NewIntegration />,
      }}
    />
  )
}
