import { useState } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from 'presentation/components/atoms'
import dayjs from 'dayjs'
import InputDate from 'presentation/components/inputDate'
import { Select, TextField } from 'presentation/components/molecules'
import { Multiselect } from 'presentation/components/organisms'
import { useForm, Controller } from 'react-hook-form'
import { StyledForm } from './style'
import { faX } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { useEffect, useRef } from 'react'

export const Filter = ({
  title = 'Filtrar',
  fields = [],
  open = true,
  isLoading = false,
  onClose = () => {},
  onFilter = (value) => {},
  onClean = () => {},
  allowEmptyFilter = false,
  ...props
}) => {
  const [errorDate, setErrorDate] = useState(false)

  const fieldsTypes = {
    text: {
      component: TextField,
      initialValue: '',
    },
    select: {
      component: Select,
      initialValue: '',
    },
    multiSelect: {
      component: Multiselect,
      initialValue: [],
    },
    date: {
      component: InputDate,
      initialValue: '',
    },
  }

  const defaultValues = (fields) => {
    return fields.reduce((value, field) => {
      const newValue = { ...value }
      newValue[field.name] = Boolean(field.initialValue) ? field.initialValue : fieldsTypes[field.type]?.initialValue
      return newValue
    }, {})
  }

  const valuesOnOpen = useRef({})

  const form = useForm({ defaultValues: defaultValues(fields) })

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    getValues,
    reset,
  } = form

  const close = () => {
    reset()
    onClose()
    setErrorDate(false)
  }

  useEffect(() => {
    if (open) valuesOnOpen.current = getValues()
    // eslint-disable-next-line
  }, [open])

  const onSubmit = (data) => {
    onFilter(data)
  }

  return (
    <Dialog open={open} onClose={close} sx={{ '& .MuiDialog-container': { justifyContent: 'flex-end' } }} {...props}>
      <DialogTitle>
        {title}
        <Icon icon={faX} sx={{ cursor: 'pointer' }} onClick={close} />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '16px 0 16px 0 !important',
          width: '360px',
        }}
      >
        <StyledForm id='filter-form' onSubmit={handleSubmit(onSubmit)}>
          {isLoading ? (
            <Box sx={{ margin: 'auto', padding: '64px 16px' }}>
              <CircularProgress />
            </Box>
          ) : (
            fields.map((field) => {
              const Component = fieldsTypes[field.type]?.component
              if (!Boolean(Component)) return null

              if (field.type === 'date') {
                return (
                  <Controller
                    key={_.uniqueId()}
                    control={control}
                    rules={field.rules}
                    name={field.name}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Component
                          error={errorDate}
                          onError={(e) => (e === 'minDate' || e === 'maxDate' ? setErrorDate(true) : null)}
                          onChange={(e) => {
                            setErrorDate(false)
                            onChange(e !== '' ? dayjs(new Date(e)) : e)
                            field?.props?.setMaxDate?.(dayjs(new Date(e)))
                            field?.props?.setSelectedIniDate?.(true)
                          }}
                          value={dayjs(new Date(value))}
                          {...field.props}
                          id={`filter_${field.name}`}
                        />
                      )
                    }}
                  />
                )
              }

              return (
                <Controller
                  key={_.uniqueId()}
                  control={control}
                  rules={field.rules}
                  name={field.name}
                  render={({ field: { value, onChange, ref } }) => (
                    <Component id={`filter_${field.name}`} value={value} onChange={onChange} {...field.props} />
                  )}
                />
              )
            })
          )}
        </StyledForm>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant='outlined'
          disabled={isLoading}
          onClick={() => {
            reset(defaultValues(fields))
            onClean()
          }}
        >
          Limpar
        </Button>
        <Button
          variant='contained'
          form='filter-form'
          type='submit'
          disabled={(!isDirty && !allowEmptyFilter) || isLoading || !isValid || errorDate}
        >
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
